import { Component, Input, Output, EventEmitter } from "@angular/core";
import { DataTypeServices } from "src/services/data-type-services";
import { MethodServices } from "src/services/method-services";
import { HttpLoaderImageComponent } from "../http-loader-image/http-loader-image.component";

@Component({
    selector: 'retry-global',
    templateUrl: 'retry-global.html',
})

export class RetryGlobal {
    
    public httpLoaderImage = HttpLoaderImageComponent

    @Input() message:any;
    @Input() height:any = "215px";  // default
    @Input() width?:any = "99%";  // default
    @Input() borderRadius?:any = "13px";  // border-radius
    @Output() clickTryOutput:any = new EventEmitter<any>()

    constructor(public methodServices:MethodServices,
                public dataTypeServices:DataTypeServices){

        this.methodServices.subject_DarkMode.subscribe((result)=>{
          this.methodServices.statusDark = result
        })
    }

    clickProc(event){
        this.clickTryOutput.emit()
    }
}