<div class = 'wrapper'>
    <label class = 'form-control-label' for = 'toggle-component'>{{ labelName | translate }}</label><br>
    <label class = 'custom-toggle'>
        <input type = 'checkbox'
                [checked]="checkboxValue"
                class = 'form-control'
                (change)='updateToggle($event.target.checked)'
                [disabled]="isDisabled">
        <span class = 'custom-toggle-slider' [attr.data-label-on]='labelOn' [attr.data-label-off]='labelOff'></span>
    </label>
</div>

<!-- Example Use of Toggle Checkbox Component -->
<!-- <toggle-checkbox-component
        [labelName]="'Active'"
        [labelOn]="'Yes'"
        [labelOff]="'No'"
        [checkboxValue]="inputGlobalValue.get('active')"
        [isDisabled]="readOnlyInput"
        (changeToggle)="changeValue('active')"
></toggle-checkbox-component> -->

<!-- Notes: labelOn utk label saat toggle true dan labelOff utk saat toggle false -->