<div class="modal-header modal-block-primary">
    <button type="button" class="close" (click)="bsModalRef.hide()">
        <span aria-hidden="true" style = 'font-size:150%'>&times;</span><span class="sr-only">Close</span>
    </button>
</div>
<div class="modal-body">
    <div class="modal-text text-center">
        <img src = "assets/img/brand/exclamation-circle_500.png" class = 'mt--3' width="100" height="100">
        <br><br>
        <h1>{{ 'Are you sure ?' | translate }}</h1>
        <p>{{ 'You have unsaved changes. Press No to go back and save these changes, or Yes to lose these changes.' | translate }}</p>
    </div>
</div>
<div class="modal-footer d-flex justify-content-center mt--3">
    <button class="btn btn-danger" (click)="action(true)">{{ 'Yes' | translate }}</button>
    <button class="btn btn-default" (click)="action(false)">{{ 'no' | translate }}</button>
</div>