import { Component, OnInit, ViewChild, ElementRef, Input, EventEmitter, Output, AfterViewInit, ViewEncapsulation, AfterViewChecked } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, debounceTime } from 'rxjs';
import { DataTypeServices } from 'src/services/data-type-services';
import { MethodServices } from 'src/services/method-services';

@Component({
    selector:'textarea-component',
    templateUrl:'textarea-component.html',
    styleUrls:['./textarea-component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class TextareaComponent implements OnInit, AfterViewInit, AfterViewChecked {
    @ViewChild('textarea') textarea:ElementRef;

    @Input() textareaId:number;
    @Input() textareaName:string;
    @Input() labelName:string = "";
    @Input() textareaValue:any = null;
    @Input() maxLengthValue:number = this.dataTypeServices.sc_description;
    @Input() rowNumber:number = 6;
    @Input() isDisabled:boolean = false;
    @Input() isResizeable:boolean = false;
    @Input() isRequired:boolean = false;
    @Input() isAutoFocus:boolean = false;
    @Input() isModelModal:boolean = false;

    lastRowNumber:number = 1;
    errorMessage = ""

    formControl = new FormControl();
    subjectInputText = new Subject();

    @Output() changeText:any = new EventEmitter<any>()

    constructor(private methodServices:MethodServices,
                public dataTypeServices:DataTypeServices){
        this.subjectInputText.pipe(debounceTime(700)).subscribe(result => {
            this.dataTypeServices.disableBtnWritten = false
            this.textareaValue = result==null ? "" : result
            this.changeText.emit(
                {
                    value:this.textareaValue=="" ? null : this.textareaValue,
                    isError: this.isRequired && (this.textareaValue == null ||  typeof(this.textareaValue) == 'undefined' || this.textareaValue.trim() == '')
                })
        })

        this.methodServices.triggerRemoveDirty.pipe().subscribe((result) => {
            if (result.showModal && this.isModelModal) {
                let textareaComponentElement = document.getElementById("textarea-id-" + this.textareaId)

                if (textareaComponentElement !== document.activeElement){
                    let findTextareaData = result['listTextarea'].find(obj => obj.textareaId == this.textareaId)

                    if (findTextareaData) {
                        this.textareaValue = findTextareaData['value']
                        if (textareaComponentElement.classList.contains("scroll-up")){
                            textareaComponentElement.classList.remove("scroll-up")
                        }
                        if (textareaComponentElement.classList.contains("scroll-down")){
                            textareaComponentElement.classList.remove("scroll-down")
                        }
                        if (findTextareaData['value']!="" && findTextareaData['value']!=null && typeof findTextareaData['value']!=undefined) {
                            this.lastRowNumber = this.rowNumber
                        } else {
                            this.lastRowNumber = 1
                        }
                    }
                }
                textareaComponentElement.style.height = (30 + (this.lastRowNumber * 21)) + "px";
                this.formControl.markAsPristine();
            }
        })
    }

    ngOnInit(){}

    updateLastRowNumber(type, event){
        let textareaComponentElement = document.getElementById(event.target.id)

        document.documentElement.style.setProperty('--textarea-row-height', this.rowNumber+"px");
        if (textareaComponentElement.classList.contains("scroll-up")) {
            textareaComponentElement.classList.remove("scroll-up")
        }
        if (textareaComponentElement.classList.contains("scroll-down")) {
            textareaComponentElement.classList.remove("scroll-down")
        }
        switch(type) {
            case "focus":
                if(event.target.value=="" || event.target.value==null || typeof event.target.value=="undefined") {
                    if (!textareaComponentElement.classList.contains("scroll-down")) {
                        textareaComponentElement.classList.add("scroll-down")
                    }
                }
                this.lastRowNumber = this.rowNumber
                break;
            case "blur":
                if(event.target.value=="" || event.target.value==null || typeof event.target.value=="undefined") {
                    if (!textareaComponentElement.classList.contains("scroll-up")) {
                        textareaComponentElement.classList.add("scroll-up")
                    }
                    this.lastRowNumber = 1
                }
                break;
        }
        textareaComponentElement.style.height = (30 + (this.lastRowNumber * 21)) + "px";
    }
    
    updateValue(event){
        this.dataTypeServices.disableBtnWritten = true
        this.errorMessage = this.textarea.nativeElement.validationMessage;
        this.textareaValue = event.target.value==null ? "" : event.target.value
        this.subjectInputText.next(this.textareaValue.trim()=="" ? null : this.textareaValue)
    }

    ngAfterViewInit(): void {
        if (this.isAutoFocus){
            setTimeout(()=>{
                this.textarea.nativeElement.focus()
            },1000)
        } 
    }

    ngAfterViewChecked(): void {
        let textareaComponentElement = document.getElementById("textarea-id-" + this.textareaId)

        if (this.isDisabled){
            textareaComponentElement.setAttribute("disabled", "disabled")
        } else {
            textareaComponentElement.removeAttribute("disabled")
        }

        if (textareaComponentElement !== document.activeElement){
            if (this.textareaValue!="" && this.textareaValue!=null && typeof this.textareaValue!=undefined) {
                this.lastRowNumber = this.rowNumber
            } else {
                this.lastRowNumber = 1
            }
            textareaComponentElement.style.height = (30 + (this.lastRowNumber * 21)) + "px";
        }
    }
}
