import {
    Component,
    ViewChild,
    Input,
    OnChanges,
    EventEmitter, Output
} from '@angular/core';
import { MethodServices } from 'src/services/method-services';
import { TranslateService } from '@ngx-translate/core';
import {HttpClient, HttpErrorResponse, HttpEventType} from "@angular/common/http";
import {map} from "rxjs/operators";
import {catchError, of} from "rxjs";

@Component({
    selector: 'uploading-file-dialog',
    templateUrl: 'uploading-file-dialog.html',
    styleUrls: ['uploading-file-dialog.scss'],
})

export class uploadingFileDialog implements OnChanges {
    @Input() isOpenDialog:boolean = false
    @Input() fileData:any = []
    @Input() headerId:string = null
    @Input() APIUploadFile:string = null
    @Output() uploadingProccess = new EventEmitter<any>()

    no_data_to_display:any = this.methodServices.langChangeList['noDataToDisplay'][this.methodServices.langArrNum]['no_data_to_display'];

    setupInformation = {
        headerId: false,
        data: false,
    }

    @ViewChild('modalUploadingDialog') public modalUploadingDialog:any;

    constructor(private methodServices: MethodServices,
                private httpClient: HttpClient,
                private translateService:TranslateService) {
        this.translateService.onLangChange.subscribe((result)=>{
            this.methodServices.langCur = result['lang']
            this.methodServices.funcLangChange(result['lang'])
            this.no_data_to_display = this.methodServices.langChangeList['noDataToDisplay'][this.methodServices.langArrNum]['no_data_to_display']
        })
    }

    ngOnChanges() {
        if (this.isOpenDialog) {
            this.modalUploadingDialog.show()
        } else {
            this.modalUploadingDialog.hide()
        }
        if (this.headerId != null) {
            this.setupInformation.headerId = true
        }
    }

    handler(attr, event) {
        if (attr == 'onShown') {
            let intervalHandler = setInterval(() => {
                if (this.setupInformation.headerId) {
                    this.fileData.forEach(file => {
                        if ((typeof file.id == "undefined" && typeof file.action == 'undefined')
                            || (typeof file.id != "undefined" && typeof file.action != 'undefined'))
                                this.uploadFile(file)
                    })
                    clearInterval(intervalHandler)
                }
            })
        } else if (attr == 'onHidden') {
            this.setupInformation = {
                headerId: false,
                data: false
            }
            this.isOpenDialog = false
            this.uploadingProccess.emit({
                status: 'closing-modal',
                msg: 'Stopping process upload'
            })
        }
    }

    uploadFile(file) {
        const formData = new FormData();
        let mapTemp = {}
        if (file.statusImageChange == 'UPDATE')
            formData.append('imagePath', this.dataURItoBlob(file.imagePath), file.fileName);
        for (var key in file) {
            if (file.hasOwnProperty(key)) {
                mapTemp[key] = file[key]
                if (key == 'isProccessing') delete mapTemp['isProccessing'];
                if (key == 'fileName') delete mapTemp['fileName'];
                if (key == 'fileSize') mapTemp['sizeInBytes'] = file[key]; delete mapTemp['fileSize'];
                if (key == 'progress') delete mapTemp['progress'];
                if (key == 'file') delete mapTemp['file'];
                if (key == 'msgStatus') delete mapTemp['msgStatus'];
                if (key == 'fileSizeLabel') delete mapTemp['fileSizeLabel'];
                if (key == 'statusImageChange') delete mapTemp['statusImageChange'];
                if (key == 'imagePath') delete mapTemp['imagePath'];
                if (key == 'indexRows') delete mapTemp['indexRows'];
                if (key == 'index') delete mapTemp['index'];
                if (key == 'activeLabel') delete mapTemp['activeLabel'];
                if (mapTemp['action'] == 'DELETE') {
                    delete mapTemp['name']
                    delete mapTemp['sequenceNo']
                    delete mapTemp['active']
                }
            }
        }
        mapTemp['feedId'] = this.headerId
        formData.append('request', window.btoa(JSON.stringify(mapTemp)));
        file.isProccessing = true;
        this.uploadingData(formData, this.APIUploadFile).pipe(
            map(event => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        file.status = 'info'
                        file.progress = Math.round(event.loaded * 100 / event.total);
                        file.msgStatus = file.action == 'DELETE' ? 'Deleting Status ' + file.progress + '%' : 'Uploading Status ' + file.progress + '%'
                        break;
                    case HttpEventType.Response:
                        return event;
                }
            }),
            catchError((error: HttpErrorResponse) => {
                file.isProccessing = false;
                file.status = 'error'
                file.msgStatus = file.action == 'DELETE' ? 'Deleting Status Error' : 'Uploading Status Error'
                return of(`upload failed.`);
            })).subscribe((event: any) => {
                if (typeof (event) === 'object') {
                    file.status = 'success'
                    file.msgStatus = file.action == 'DELETE' ? 'Deleting Status Success' : 'Uploading Status Success'
                    let cekAllStatusError = this.fileData.every(res => res.status == 'error')
                    if (!cekAllStatusError) {
                        let cekStatus = this.fileData.findIndex(res => res.status == 'info' && typeof res.action != 'undefined')
                        if (cekStatus == -1) {
                            this.uploadingProccess.emit({
                                status: 'success',
                                msg: 'Updating Image Successfully!'
                            })
                        }
                    } else {
                        this.uploadingProccess.emit({
                            status: 'error',
                            msg: 'All uploading data got error!'
                        })
                    }
                }
        });
    }

    uploadingData(formData,api) {
        return this.httpClient.post<any>(this.methodServices.jwt_instance_api + api, formData, {
            headers: {
                Authorization:'Bearer ' + this.methodServices.jwt_access_token,
                'X-TenantID':this.methodServices.jwt_tenant_id,
                AuthorizationToken:this.methodServices.jwt_authorities_value,
            },
            reportProgress: true,
            observe: 'events'
        });
    }

    dataURItoBlob(dataURI) {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        var byteString = atob(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        //New Code
        return new Blob([ab], {type: mimeString});
    }
}
