import { Component, Input, OnInit, ViewEncapsulation, Output, EventEmitter } from "@angular/core";
import { ActivatedRoute } from '@angular/router';

export const talentTitleList:any[] = [
    {id:1,name:'Risk'},
    {id:2,name:'Inconsistent Player'},
    {id:3,name:'Average Performer'},
    {id:4,name:'Potential Gem'},
    {id:5,name:'Core Player'},
    {id:6,name:'Solid Performer'},
    {id:7,name:'High Potential'},
    {id:8,name:'High Performer'},
    {id:9,name:'Star'},
]

@Component({
    selector: 'talent-matrix-global',
    templateUrl: 'talent-matrix.html',
    encapsulation: ViewEncapsulation.None,
})

export class TalentMatrixGlobal implements OnInit {

    @Input() lookup:any;
    @Input() valueDefault:any;
    @Input() statusDisabled:boolean = false;
    @Input() aodTop:any;
    @Output() talentPositionChange = new EventEmitter<any>()
    @Input() size:any = ''
    @Input() editShow:boolean = false;
    @Input() arrInput:any[] = []
    @Output('changeObjInput') changeObjInput = new EventEmitter<any>()
    @Input() showTalentMatrix:boolean = false

    @Input('objInput') set objInput(value: any)
    {
        this._objInput = value
        this.changeObjInput.emit(this._objInput);
    };
    get objInput() {
        return this._objInput;
    }

    private _objInput:any = {}
    
    talentTitleList = [];

    constructor(private activatedRoute:ActivatedRoute){
    }    

    ngOnInit(){
        this.activatedRoute.queryParams.subscribe((result)=>{
            this.talentTitleList = talentTitleList
        })  
    }

    changeObj() {
        this.objInput = this.objInput
    }

    showProc(position){
        this.talentPositionChange.next(position)
    }
}   
