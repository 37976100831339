import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, debounceTime } from 'rxjs';
import { DataTypeServices } from 'src/services/data-type-services';
import { MethodServices } from 'src/services/method-services';

@Component({
  selector: 'app-input-global',
  templateUrl: './input-global.component.html',
  styleUrls: ['./input-global.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InputGlobalComponent implements OnInit, AfterViewChecked {
  @ViewChild('inputNumber',{read:ElementRef}) inputNumber: ElementRef<HTMLInputElement>
  @ViewChild('inputText',{read:ElementRef}) inputText: ElementRef<HTMLInputElement>

  @Input() maxLength;
  @Input() isRequired:boolean = false;
  @Input() value;
  @Input() id:string;
  @Input() name:string;
  @Input() label:string;
  @Input() type:'text' | 'number';
  
  //untuk input number
  @Input() disableStatus:boolean = false;
  @Input() min;
  @Input() step = "any";

  //penunjuk modal
  @Input() isModelModal:boolean = false;
  
  @Output() changeValue:any = new EventEmitter<any>();
  @Output() wheelProc:any = new EventEmitter<any>();

  formControl = new FormControl();
  subjectInput = new Subject()
 
  errorMessage = ""

  constructor(
    public dataTypeServices:DataTypeServices,
    public methodServices:MethodServices,
  ){
    this.subjectInput.pipe(
      debounceTime(700)
    ).subscribe((result:any) => {
      this.dataTypeServices.disableBtnWritten = false
      if(this.type =='number'){
        this.changeValue.emit(
          {
            value:(result != null &&  typeof(result) != 'undefined' && result.toString().trim() != '' ? parseFloat(result) : null), name: this.name,
            isError: this.isRequired && (result == null ||  typeof(result) == 'undefined' || result.toString().trim() == '') 
          }
        )
      } else {
          this.changeValue.emit(
            {
              value:(result != null &&  typeof(result) != 'undefined' && result.trim() != '' ? result.trim() : null), 
              name: this.name,
              isError: this.isRequired && (result == null ||  typeof(result) == 'undefined' || result.trim() == '')
            }
          )
      }
    })

    this.methodServices.triggerRemoveDirty.pipe().subscribe((result) => {
      if (result.showModal && this.isModelModal) {
        this.formControl.markAsPristine();
      }
    })
  }

  ngOnInit(): void {
    if(this.disableStatus == undefined){
      this.disableStatus = false
    } 
  }

  change(event){
    this.dataTypeServices.disableBtnWritten = true
    if (this.type=="text") {
      this.errorMessage = this.inputText.nativeElement.validationMessage;
      this.subjectInput.next(this.inputText.nativeElement.value);
    } else {
      this.errorMessage = this.inputNumber.nativeElement.validationMessage;
      this.subjectInput.next(this.inputNumber.nativeElement.value);
    }
  }

  wheel(){
    this.wheelProc.emit('');
  }

  inpNum(e) {
    e = e || window.event;
    var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
    var charStr = String.fromCharCode(charCode);
    if (!charStr.match(/^[0-9]+$/) && charCode != 46)
      e.preventDefault();
  }

  //masih error pada pada balance limit
  ngAfterViewChecked(): void {
    let inputGlobalComponentElement = document.getElementById(this.id)

    if (this.disableStatus){
        inputGlobalComponentElement.setAttribute("disabled", "disabled")
    } else {
        inputGlobalComponentElement.removeAttribute("disabled")
    }
  }
}
