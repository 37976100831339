<div class="form-group" [ngClass]="{'required':gitRequired}">
    <label class="custom-calendar w-100">
        <input [ngxMatDatetimePicker]="picker"
                type="text"
                placeholder=" "
                [ngClass]="modelDatepicker == 'Invalid Date' ? 'invalidError datetimepicker-field': ' datetimepicker-field'"
                class="{{gitClass}}"
                [value]="modelDatepicker"
                autocomplete="off"
                autocapitalize="off"
                spellcheck="false"
                (dateChange)="changeProcDate($event)"
                (click)="openDatepicker()"
                [disabled]="isDisabled"
                [readOnly]="true">
        <label class="form-input-label">{{gitLabel | translate}}</label>
        <ngx-mat-datetime-picker #picker [showSpinners]="true" [showSeconds]="false"
                                    [stepHour]="1" [stepMinute]="1" [stepSecond]="1" [touchUi]="false"
                                    [enableMeridian]="false">
        </ngx-mat-datetime-picker>
    </label>
</div>


