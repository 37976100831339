// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  tokenUrl:
    "https://sso.gitsolutions.id/auth/realms/git/protocol/openid-connect/token",
  encryptKey: "q@36^5vGFK)~z#rW",
  secretKey: "secret-key-58923",
  apiDomain_Lookup: "https://ng-api-dev.gitsolutions.id",
  baseUrlImage: "/api/images?path=",
  baseUrl: "https://ng-api-dev.gitsolutions.id", // NOT USE, ONLY FOR OTHER METHOD SERVICE
  urlApiMaster: "https://ng-api-master-dev.gitsolutions.id",
  loginUrl: "https://ng-login-dev.gitsolutions.id/login",
  mapApiKey: "AIzaSyBylcbhGHIgdSw-WCgExoB2wQp-fFy4Oco",
  basicAuthentication: "Basic dGFsZW50c19tdGY6cWEzNGhkamZ5dHU0NXJ0ZmRzZmJnZmo=", //IDS
  timeoutms: 35000,
  urlSnapMidtrans: "https://app.sandbox.midtrans.com/snap/snap.js",
  clientKeyMidtrans: "SB-Mid-client-HuqYf2rku3qULDDd",
};
