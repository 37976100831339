//   <div (outDropFile)='procUpload($event)' appDragDropDirective>
//         Masuk Sini
// </div>

import { Directive, HostListener, HostBinding, ElementRef, EventEmitter, Output } from '@angular/core';

@Directive({
    selector: '[appDragDropDirective]',
})
export class DragDropDirectiveDirective {

  @Output() outDropFile = new EventEmitter<any>();
  
  constructor(private ele:ElementRef) { 
    // ele.nativeElement.style.backgroundColor = "black"
  }

  // @HostListener('mouseenter') public onMouseEnter(){
  //   this.ele.nativeElement.style.backgroundColor = "red"
  // }
  // @HostListener('mouseleave') public onMouseleave(){
  //   this.ele.nativeElement.style.backgroundColor = "transparent"
  // }

  @HostListener('dragover',['$event']) public ondragover(event){
    event.preventDefault();
    event.stopPropagation();
    console.log('Drag Over')
  }
  @HostListener('dragleave',['$event']) public ondragleave(event){
    event.preventDefault();
    event.stopPropagation();
    console.log('Drag Leave')
  }
  @HostListener('drop',['$event']) public ondrop(evt){
    evt.preventDefault();
    evt.stopPropagation();
    if (evt.dataTransfer.files.length > 0){
      this.outDropFile.emit(evt.dataTransfer.files)
      console.log(`you Dropped ${evt.dataTransfer.files} !`)
    }
    
  }
}
