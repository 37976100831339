import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { MethodServices } from 'src/services/method-services';

@Component({
  selector: 'app-confirm-leave',
  templateUrl: './confirm-leave-component.html',
})
export class ConfirmLeaveComponent {

  subject: Subject<boolean>;

  constructor(public bsModalRef: BsModalRef,
              private methodServices:MethodServices,
              private router:Router) { }

  @HostListener('document:keydown',['$event'])
    procKeyDown(event:KeyboardEvent){
        if(event.keyCode == 13){
            this.action(true)
        }
    }

  action(value: boolean) {
    this.bsModalRef.hide();
    this.subject.next(value);
    this.subject.complete();
    if (value){
      this.customBackRouter((result)=>{
        if(!result){
          this.methodServices.aktif_table.next(true)
        }
      }) 
    }
  }

  customBackRouter(callback){
    let currentRouter = this.router.url
    if(currentRouter.includes("/recruitment/applicant-selection/form")){
      callback(true)
      return
    }
    if (currentRouter.includes("/recruitment/applicant-screening/applicant/education") || 
        currentRouter.includes("/recruitment/applicant-screening/applicant/organizational") || 
        currentRouter.includes("/recruitment/applicant-screening/applicant/certification") || 
        currentRouter.includes("/recruitment/applicant-screening/applicant/work-experience") || 
        currentRouter.includes("/recruitment/applicant-screening/applicant/document")){
      callback(true)
      return
    }
    callback(false)
  }
}
