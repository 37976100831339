import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from "@angular/core";
import { MethodServices } from 'src/services/method-services';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'timeline-global',
    templateUrl: 'timeline.html',
})

export class TimelineGlobal implements OnInit, OnChanges {

    @Input() lookup:any;
    @Input() valueDefault:any;
    @Input() statusDisabled:boolean = false;
    @Input() aodTop:any;
    @Output() lookupChange = new EventEmitter<any>()
    @Input() size:any = ''
    @Input() param:any;
    @Input() focusNgSelect:any;
    @Input() gitLabel:any;
    @Input() gitRequired:any;
    @Input() editShow:boolean = false;
    @Input() arrInput:any[] = []
    
    constructor(private methodServices:MethodServices,
            private activatedRoute:ActivatedRoute){
    }

    ngOnInit(){
        
        this.activatedRoute.queryParams.subscribe((result)=>{
            this.arrInput = []
        })  
    }

    ngOnChanges(){
        if (this.arrInput.length > 0){
            let arrInputTemp = this.arrInput.map((prop)=>{
                let tempArr = {}
                tempArr = {
                    ...prop
                }

                this.methodServices.imageExists(prop['profilePicture'], (exists) => {
                    if (exists){
                        tempArr['backgroundAvatar'] = "grey"
                    }    
                    else{
                        tempArr['backgroundAvatar'] = this.methodServices.getRandomColor()
                    }
                })
                return tempArr

            })
            
            this.arrInput = arrInputTemp
        }
    }
}   
