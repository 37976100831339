import { Component, Input, ViewChild, ElementRef, HostListener, OnInit, OnDestroy } from "@angular/core";
import { MethodServices } from 'src/services/method-services'
import { Router, ActivatedRoute } from '@angular/router';
import { CanActivateGuardServices } from "src/services/canActivate-guard-services";
import { Subject } from "rxjs";
import { DataTypeServices } from "src/services/data-type-services";

export const menuEmployeeExport:any[] = [
    'Personal','Address','Employment','Assignment','Element Entries','Payroll Result','Time Card',
    'Family','Education','Certification','Work Experience','Document', 'Client','Project','Absence','Calendar','Benefit', 'Loan', 'Checklist'
    , 'Questionnaire','Goal','Performance','Competencies','Asset', 'Point History', 'Medical', 'Career Assessment', 'Job Qualification', 
    'Talent Matrix', 'Letter'
]

export const menuApplicantExport:any[] = [
    'Personal', 'Education', 'Organizational', 'Certification', 'Work Experience', 'Document', 'Job Applied'
]

@Component({
    selector: 'global-hamburger-menu',
    templateUrl: 'hamburger-menu-component.html',
    styleUrls: ['hamburger-menu-component.scss']
})

export class GlobalHamburgerMenuComponent implements OnInit, OnDestroy {
    aktif:boolean = false
    @ViewChild('menu') menu:ElementRef;
    @ViewChild('hamburgerMenu') hamburgerMenu:ElementRef;
    @Input() inputKeyjson:any;
    @Input() inputAOD:any;
    @Input() inputStt:any;
    @Input() menuActive:any;
    @Input() hamburgerMenuFor:any;
    dataObj = {}
    indexHamburger:number = 0;

    menuEmployee_Final:any[] = [];

    selectedItem:any;
    // menuEmployee = []
    menuEmployee = ['personal','Address','Employment','Assignment','Element Entries','Payroll Result','Time Card',
        'Family','Education','Certification','Work Experience','Document','Client','Project','Absence','Calendar','Benefit', 'Loan', 'Checklist'
        , 'Questionnaire','Goal','Performance','Competencies','Asset', 'Point History', 'Medical', 'Career Assessment', 'Job Qualification', 
        'Talent Matrix', 'Letter'
    ]

    menuApplicant = ['Personal', 'Education', 'Organizational', 'Certification', 'Work Experience', 'Document', 'Job Applied']

    ngUnsubscribe:Subject<any> = new Subject();

    constructor(private methodServices:MethodServices,
                public dataTypeServices:DataTypeServices,
                private router:Router,
                private canActivateGuardServices:CanActivateGuardServices,
                private activatedRoute:ActivatedRoute){

        this.selectedItem = this.menuActive
    }

    @HostListener('window:blur',['$event'])
    windowBlur(event: any){
        this.aktif = false
        this.dataTypeServices.disabledEmployeeFormMenu = this.aktif
    }
    
    ngOnDestroy(): void {
        this.dataTypeServices.disabledEmployeeFormMenu = false
        this.ngUnsubscribe.next(void 0)
        this.ngUnsubscribe.complete() 
    }

    convert_to_path(path:any):string{
      let path_temp:any = '';
      if (this.hamburgerMenuFor == 'Employee'){
        switch(path.toUpperCase()){
          case 'PERSONAL': path_temp = 'personal';break;
          case 'ADDRESS' : path_temp = 'address';break;
          case 'EMPLOYMENT' : path_temp = 'employment';break;
          case 'ASSIGNMENT' : path_temp = 'assignment';break;
          case 'ELEMENT ENTRIES' : path_temp = 'element-entries';break;
          case 'PAYROLL RESULT' : path_temp = 'payroll-result';break;
          case 'TIME CARD' : path_temp = 'time-card';break;
          case 'ABSENCE' : path_temp = 'absence';break;
          case 'BENEFIT' : path_temp = 'benefit';break;
          case 'FAMILY' : path_temp = 'family';break;
          case 'EDUCATION' : path_temp = 'education';break;
          case 'CERTIFICATION' : path_temp = 'certification';break;
          case 'WORK EXPERIENCE' : path_temp = 'work-experience';break;
          case 'DOCUMENT' : path_temp = 'document';break;
          case 'CLIENT' : path_temp = 'client';break;
          case 'PROJECT' : path_temp = 'project';break;
          case 'LOAN' : path_temp = 'loan';break;
          case 'CHECKLIST' : path_temp = 'checklist';break;
          case 'CALENDAR' : path_temp = 'calendar';break;
          case 'QUESTIONNAIRE' : path_temp = 'questionnaire';break;
          case 'GOAL' : path_temp = 'goal';break;
          case 'PERFORMANCE' : path_temp = 'performance';break;
          case 'COMPETENCIES' : path_temp = 'competencies';break;
          case 'ASSET' : path_temp = 'asset';break;
          case 'POINT HISTORY' : path_temp = 'point-history';break;
          case 'MEDICAL' : path_temp = 'medical';break;
          case 'CAREER ASSESSMENT': path_temp = 'career-assessment';break;
          case 'JOB QUALIFICATION': path_temp = 'job-qualification';break;
          case 'TALENT MATRIX': path_temp = 'talent-matrix';break;
          case 'LETTER': path_temp = 'letter';break;
        }
      }
        return path_temp;
    }

    ngOnInit(){
        this.activatedRoute.queryParams.subscribe((result)=>{
            if (this.hamburgerMenuFor == 'Employee') {
                if (this.methodServices.locationPath() == 'PERSONAL'){
                    if (typeof(result.stt) != 'undefined' &&
                        this.methodServices.ivSha256Decrypt(result.stt) == 'new')
                    {
                        this.menuEmployee = ['Personal']
                        // dropdownItem.item(0).classList.add('active-dropdown-item')
                    }
                    else{
                        this.menuEmployee = ['Personal','Address','Employment','Assignment','Element Entries','Payroll Result','Time Card',
                            'Family','Education','Certification','Work Experience','Document','Client','Project','Absence','Calendar','Benefit', 
                            'Loan','Checklist','Questionnaire','Goal','Performance','Competencies','Asset','Point History', 'Medical', 
                            'Career Assessment', 'Job Qualification', 'Talent Matrix', 'Letter'
                        ]
                    }
                }
                else{
                    this.menuEmployee = ['Personal','Address','Employment','Assignment','Element Entries','Payroll Result','Time Card',
                        'Family','Education','Certification','Work Experience','Document','Client','Project','Absence','Calendar','Benefit', 
                        'Loan','Checklist', 'Questionnaire','Goal','Performance','Competencies','Asset','Point History', 'Medical', 
                        'Career Assessment', 'Job Qualification', 'Talent Matrix', 'Letter'
                    ]
                }

                // PENYESUAIAN MENU EMPLOYEE yang valid untuk ditampilkan
                
                this.menuEmployee_Final = [];
                this.menuEmployee.forEach((ele,idx)=>{
                    if (this.canActivateGuardServices.check_authentical({tipe_check:'path',path:'/workforce/employee/' + this.convert_to_path(ele)})){
                      this.menuEmployee_Final.push(ele);
                    }
                })

            } else if (this.hamburgerMenuFor == 'Applicant') {
                if (this.methodServices.locationPath() == 'PERSONAL') {
                    if (typeof result.stt != 'undefined'
                        && this.methodServices.ivSha256Decrypt(result.stt) == 'new') {
                        this.menuApplicant = ['Personal']
                    } else {
                        this.menuApplicant = ['Personal', 'Education', 'Organizational', 'Certification', 'Work Experience', 'Document', 'Job Applied']
                    }
                } else {
                    this.menuApplicant = ['Personal', 'Education', 'Organizational', 'Certification', 'Work Experience', 'Document', 'Job Applied']
                }
            }
            this.selectedItem = this.menuActive
        })
    }

    activeDropdownItem(index){
        let dropdownItem = document.getElementsByClassName('dropdown-item')
        dropdownItem[index].classList.add('active-dropdown-item')
    }

    navigateProc(event,index){
        let dropdownItem = document.getElementsByClassName('dropdown-item')
        this.aktif = false
        this.dataTypeServices.disabledEmployeeFormMenu = this.aktif
        this.selectedItem = event

        this.dataObj = {}
        if (typeof(this.inputKeyjson) != 'undefined' && this.inputKeyjson != '' && this.inputKeyjson != null){
            let tempInputKeyJson = JSON.parse(this.methodServices.ivSha256Decrypt(this.inputKeyjson))
            tempInputKeyJson['linkMenu'] = 'link'
            this.inputKeyjson = this.methodServices.ivSha256Encrypt(JSON.stringify(tempInputKeyJson))
            this.dataObj['keyjson'] = this.inputKeyjson
        }

        if (typeof(this.inputAOD) != 'undefined' && this.inputAOD != '' && 
                this.inputAOD != null){
            this.dataObj['aod'] = this.inputAOD
        }
        if (typeof(this.inputStt) != 'undefined' && this.inputStt != '' && 
            this.inputStt != null){
            this.dataObj['stt'] = this.inputStt
        }

        if (this.hamburgerMenuFor == 'Employee') {
            switch(event.toUpperCase()){
                case 'PERSONAL' :
                    this.selectedItem = 'Personal';
                    this.methodServices.firstLoadInc = 0;
                    this.methodServices.aktif_table.next(false)
                    this.router.navigate(['workforce','employee','personal'],{
                        queryParams:this.dataObj
                    });
                    break;
                case 'ADDRESS' :
                    this.selectedItem = 'Address';
                    this.methodServices.firstLoadInc = 0;
                    this.router.navigate(['workforce','employee','address'],{
                        queryParams:this.dataObj
                    });
                    break;
                case 'EMPLOYMENT' :
                    this.selectedItem = 'Employment';
                    this.methodServices.firstLoadInc = 0;
                    this.methodServices.aktif_table.next(false)
                    this.router.navigate(['workforce','employee','employment'],{
                        queryParams:this.dataObj
                    });
                    break;
                case 'ASSIGNMENT' :
                    this.selectedItem = 'Assignment';
                    this.methodServices.firstLoadInc = 0;
                    this.router.navigate(['workforce','employee','assignment'],{
                        queryParams:this.dataObj
                    });
                    break;
                case 'ELEMENT ENTRIES' :
                    this.selectedItem = 'Element Entries';
                    this.methodServices.firstLoadInc = 0;
                    this.router.navigate(['workforce','employee','element-entries'],{
                        queryParams:this.dataObj
                    });
                    break;
                case 'PAYROLL RESULT' :
                    this.selectedItem = 'Payroll Result';
                    this.methodServices.firstLoadInc = 0;
                    this.router.navigate(['workforce','employee','payroll-result'],{
                        queryParams:this.dataObj
                    });
                    break;
                case 'TIME CARD' :
                    this.selectedItem = 'Time Card';
                    this.methodServices.firstLoadInc = 0;
                    this.router.navigate(['workforce','employee','time-card'],{
                        queryParams:this.dataObj
                    });
                    break;
                case 'FAMILY' :
                    this.selectedItem = 'Family';
                    this.methodServices.firstLoadInc = 0;
                    this.router.navigate(['workforce','employee','family'],{
                        queryParams:this.dataObj
                    });
                    break
                case 'EDUCATION' :
                    this.selectedItem = 'Education';
                    this.methodServices.firstLoadInc = 0;
                    this.router.navigate(['workforce','employee','education'],{
                        queryParams:this.dataObj
                    });
                    break
                case 'CERTIFICATION' : this.selectedItem = 'Certification';
                    this.methodServices.firstLoadInc = 0;
                    this.router.navigate(['workforce','employee','certification'], {
                        queryParams:this.dataObj
                    });
                    break
                case 'WORK EXPERIENCE' :
                    this.selectedItem = 'Work Experience';
                    this.methodServices.firstLoadInc = 0;
                    this.router.navigate(['workforce','employee','work-experience'], {
                        queryParams:this.dataObj
                    });
                    break
                case 'DOCUMENT' :  this.selectedItem = 'Document';
                    this.methodServices.firstLoadInc = 0;
                    this.router.navigate(['workforce','employee','document'], {
                        queryParams:this.dataObj
                    });
                    break
                case 'CLIENT' :  this.selectedItem = 'Client';
                    this.methodServices.firstLoadInc = 0;
                    this.router.navigate(['workforce','employee','client'], {
                        queryParams:this.dataObj
                    });
                    break
                case 'PROJECT' :  this.selectedItem = 'Project';
                    this.methodServices.firstLoadInc = 0;
                    this.router.navigate(['workforce','employee', 'project'], {
                        queryParams:this.dataObj
                    });
                    break;
                case 'ABSENCE' :  this.selectedItem = 'Absence';
                    this.methodServices.firstLoadInc = 0;
                    this.router.navigate(['workforce','employee','absence'], {
                        queryParams:this.dataObj
                    });
                    break
                case 'BENEFIT' :  this.selectedItem = 'Benefit';
                    this.methodServices.firstLoadInc = 0;
                    this.router.navigate(['workforce','employee','benefit'], {
                        queryParams:this.dataObj
                    });
                    break
                case 'LOAN' : this.selectedItem = 'Loan';
                    this.methodServices.firstLoadInc = 0;
                    this.router.navigate(['workforce','employee', 'loan'], {
                        queryParams:this.dataObj
                    });
                    break;
                case 'CHECKLIST' :  this.selectedItem = 'Checklist';
                    this.methodServices.firstLoadInc = 0;
                    this.router.navigate(['workforce','employee','checklist'], {
                        queryParams:this.dataObj
                    });
                    break
                case 'QUESTIONNAIRE' :  this.selectedItem = 'Questionnaire';
                    this.methodServices.firstLoadInc = 0;
                    this.router.navigate(['workforce','employee','questionnaire'], {
                        queryParams:this.dataObj
                    });
                    break
                case 'USER ACCESS' : this.selectedItem = 'User Access';break
                case 'CALENDAR' : this.selectedItem = 'Calendar';
                    this.methodServices.firstLoadInc = 0;
                    this.router.navigate(['workforce','employee','calendar'], {
                        queryParams:this.dataObj
                    });
                    break
                case 'GOAL' : this.selectedItem = 'Goal';
                    this.methodServices.firstLoadInc = 0;
                    this.router.navigate(['workforce','employee','goal'], {
                        queryParams:this.dataObj
                    });
                    break
                case 'PERFORMANCE' : this.selectedItem = 'Performance';
                    this.methodServices.firstLoadInc = 0;
                    this.router.navigate(['workforce','employee','performance'], {
                        queryParams:this.dataObj
                    });
                    break
                case 'COMPETENCIES' : this.selectedItem = 'Competencies';
                    this.methodServices.firstLoadInc = 0;
                    this.router.navigate(['workforce','employee','competencies'], {
                        queryParams:this.dataObj
                    });
                    break
                case 'ASSET' : this.selectedItem = 'Asset';
                    this.methodServices.firstLoadInc = 0;
                    this.router.navigate(['workforce','employee','asset'], {
                        queryParams:this.dataObj
                    });
                    break
                case 'POINT HISTORY' : this.selectedItem = 'Point History';
                    this.methodServices.firstLoadInc = 0;
                    this.router.navigate(['workforce','employee','point-history'], {
                        queryParams:this.dataObj
                    });
                    break
                case 'MEDICAL' : this.selectedItem = 'Medical';
                    this.methodServices.firstLoadInc = 0;
                    this.router.navigate(['workforce','employee','medical'], {
                        queryParams:this.dataObj
                    });
                    break
                case 'CAREER ASSESSMENT' : this.selectedItem = 'Career Assessment';
                    this.methodServices.firstLoadInc = 0;
                    this.router.navigate(['workforce','employee','career-assessment'], {
                        queryParams:this.dataObj
                    });
                    break
                case 'JOB QUALIFICATION' : this.selectedItem = 'Job Qualification';
                    this.methodServices.firstLoadInc = 0;
                    this.router.navigate(['workforce','employee','job-qualification'], {
                        queryParams:this.dataObj
                    });
                    break
                case 'TALENT MATRIX' : this.selectedItem = 'Talent Matrix';
                    this.methodServices.firstLoadInc = 0;
                    this.router.navigate(['workforce','employee','talent-matrix'], {
                        queryParams:this.dataObj
                    });
                    break
                case 'LETTER' : this.selectedItem = 'Letter';
                    this.methodServices.firstLoadInc = 0;
                    this.router.navigate(['workforce','employee','letter'], {
                        queryParams:this.dataObj
                    });
                    break
            }
        } else if (this.hamburgerMenuFor == 'Applicant') {
            switch (event.toUpperCase()) {
                case 'PERSONAL': this.selectedItem = 'Personal';
                    this.methodServices.firstLoadInc = 0;
                    this.router.navigate(['recruitment', 'applicant-screening','applicant','personal'], {
                        queryParams:this.dataObj
                    });
                    break;
                case 'EDUCATION': this.selectedItem = 'Education';
                    this.methodServices.firstLoadInc = 0;
                    this.router.navigate(['recruitment', 'applicant-screening', 'applicant', 'education'], {
                        queryParams:this.dataObj
                    })
                    break;
                case 'ORGANIZATIONAL': this.selectedItem = 'Organizational';
                    this.methodServices.firstLoadInc = 0;
                    this.router.navigate(['recruitment', 'applicant-screening', 'applicant', 'organizational'], {
                        queryParams:this.dataObj
                    })
                    break;
                case 'CERTIFICATION': this.selectedItem = 'Certification';
                    this.methodServices.firstLoadInc = 0;
                    this.router.navigate(['recruitment', 'applicant-screening', 'applicant', 'certification'], {
                        queryParams:this.dataObj
                    })
                    break;
                case 'WORK EXPERIENCE': this.selectedItem = 'Work Experience';
                    this.methodServices.firstLoadInc = 0;
                    this.router.navigate(['recruitment', 'applicant-screening', 'applicant', 'work-experience'], {
                        queryParams:this.dataObj
                    })
                    break;
                case 'DOCUMENT': this.selectedItem = 'Document';
                    this.methodServices.firstLoadInc = 0;
                    this.router.navigate(['recruitment', 'applicant-screening', 'applicant', 'document'], {
                        queryParams:this.dataObj
                    })
                    break;
                case 'JOB APPLIED': this.selectedItem = 'Job Applied';
                    this.methodServices.firstLoadInc = 0;
                    this.router.navigate(['recruitment', 'applicant-screening', 'applicant', 'job-applied'], {
                        queryParams:this.dataObj
                    })
                    break;
            }
        }
        this.selectedItemLabel()
    }

    selectedItemLabel(){
        let curLoc = this.methodServices.locationMenuPath(true)
        let curLocFinal = curLoc[curLoc.length-1]

        if (curLocFinal.toUpperCase() != this.selectedItem.toUpperCase()){
            if (this.hamburgerMenuFor == 'Employee') {
                switch(curLocFinal.toUpperCase()){
                    case 'PERSONAL' : this.selectedItem = 'Personal';break;
                    case 'ADDRESS' : this.selectedItem = 'Address';break;
                    case 'EMPLOYMENT' : this.selectedItem = 'Employment';break;
                    case 'ASSIGNMENT' : this.selectedItem = 'Assignment';break;
                    case 'ELEMENT-ENTRIES' : this.selectedItem = 'Element Entries';break;
                    case 'PAYROLL RESULT' : this.selectedItem = 'Payroll Result';break;
                    case 'TIME CARD' : this.selectedItem = 'Time Card';break;
                    case 'ABSENCE' : this.selectedItem = 'Absence';break
                    case 'BENEFIT' : this.selectedItem = 'Benefit';break
                    case 'FAMILY' : this.selectedItem = 'Family';break
                    case 'EDUCATION' : this.selectedItem = 'Education';break
                    case 'CERTIFICATION' : this.selectedItem = 'Certification';break
                    case 'WORK EXPERIENCE' : this.selectedItem = 'Work Experience';break
                    case 'DOCUMENT' : this.selectedItem = 'Document';break
                    case 'ABSENCE' : this.selectedItem = 'Absence';break
                    case 'BENEFIT' : this.selectedItem = 'Benefit';break
                    case 'USER ACCESS' : this.selectedItem = 'User Access';break
                    case 'CALENDAR' : this.selectedItem = 'Calendar';break
                    case 'GOAL' : this.selectedItem = 'Goal';break
                    case 'PERFORMANCE' : this.selectedItem = 'Performance';break
                    case 'SKILL-AND-COMPETENCIES' : this.selectedItem = 'Competencies';break
                    case 'ASSET' : this.selectedItem = 'Asset';break
                    case 'POINT HISTORY' : this.selectedItem = 'Point History';break
                    case 'MEDICAL' : this.selectedItem = 'Medical';break
                    case 'CAREER-ASSESSMENT' : this.selectedItem = 'Career Assessment';break;
                    case 'JOB QUALIFICATION' : this.selectedItem = 'Job Qualification';break;
                    case 'TALENT MATRIX' : this.selectedItem = 'Talent Matrix';break;
                    case 'LETTER' : this.selectedItem = 'Letter';break;
                }
            } else if (this.hamburgerMenuFor == 'Applicant') {
                switch (curLocFinal.toUpperCase()) {
                    case 'PERSONAL': this.selectedItem = 'Personal';break;
                    case 'EDUCATION': this.selectedItem = 'Education';break;
                    case 'ORGANIZATIONAL': this.selectedItem = 'Organizational';break;
                    case 'CERTIFICATION': this.selectedItem = 'Certification';break;
                    case 'WORK EXPERIENCE': this.selectedItem = 'Work Experience';break;
                    case 'DOCUMENT': this.selectedItem = 'Document';break;
                    case 'JOB APPLIED': this.selectedItem = 'Job Applied';break;
                }
            }
        }
    }
    
    toggleProc(){
        this.aktif = !this.aktif
        this.dataTypeServices.disabledEmployeeFormMenu = this.aktif
    }
}
