import { Component, ViewChild, ElementRef, AfterViewInit, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { DataTypeServices } from 'src/services/data-type-services';

@Component({
    selector:'global-autonumeric-component',
    templateUrl:'autonumeric-component.html',
})

export class GlobalAutoNumericComponent implements AfterViewInit, OnChanges {
    @ViewChild('autoNumerics') autoNumerics:ElementRef;
    @Output() changeNumeric = new EventEmitter<any>()
    @Input() canNegativeNumber:boolean = false
    @Input() fractionDigits:number = 2
    @Input() style:any = 'currency';
    @Input() sizeForm:any = '';
    fractionVar:number = 2
    invalidNumber:boolean = false;
    @Input() modelAutoNumeric:number;
    firstLoad:boolean = true
    @Input() isDisabled:boolean = false;
    @Input() currencyLabel:any = '';
    @Input() showCurrencyLabel:boolean = false;
    @Input() placeholderLabel:any = "";

    isFocusable:boolean = false

    constructor(public dataTypeServices:DataTypeServices){
            
    }

    ngAfterViewInit(){
        this.firstLoad = false
    }

    autoNumeric(event,attr){
        this.isFocusable = false
        let val;
        let valRp;
        let valRev;
        if (!this.firstLoad){
            try{
                if(event.target.value.toString().trim() == '' || event.target.value == null){
                    this.invalidNumber = false
                    this.changeNumeric.emit('')
                    this.autoNumerics.nativeElement.value = null
                    return
                }
            }catch(err){
                if (event==null || typeof (event) == 'undefined'){
                    this.invalidNumber = false
                    this.changeNumeric.emit('')
                    this.autoNumerics.nativeElement.value = null
                    return
                }else
                if (event.toString() == '')
                {
                    this.invalidNumber = false
                    this.changeNumeric.emit('')
                    this.autoNumerics.nativeElement.value = null
                    return
                }
            }

            if (typeof(event) == 'number'){
                val = event
                valRev = event
            }else if (typeof(event) == 'string'){
                val = event.replace(/\./g,'')
                valRp = val.replace('Rp','')
                valRev = Number(valRp.replace(/\u00a0/g, '').replace(',','.'))
            }else
            {
                val = event.target.value.replace(/\./g,'')
                valRp = val.replace('Rp','').replace(/\u00a0/g, '')
                valRev = Number(valRp.replace(',','.'))
            }

            let maximumFractionDigits:number = this.fractionDigits
            let minimumFractionDigits:number = this.fractionDigits
            let options = {
                style:this.style,
                currency:'IDR',
                maximumFractionDigits:maximumFractionDigits,
                minimumFractionDigits:minimumFractionDigits
            }

            if (maximumFractionDigits == 0){
                let temp = Math.floor(valRev)
                let valFormat;

                if (temp.toString() != 'NaN'){
                    valFormat = new Number(temp).toLocaleString('id-ID',options)
                }else{
                    valFormat = 'NaN'
                }

                this.autoNumerics.nativeElement.value = valFormat.toString().replace('Rp','').replace(/\u00a0/g, '')
                this.modelAutoNumeric = valFormat.toString().replace('Rp','').replace(/\u00a0/g, '')

                this.invalidNumber = temp.toString() == 'NaN' ? true : false
                if (attr == 'change'){
                    this.changeNumeric.emit(Number(valFormat.replace(/\./g,'').replace(',','.').replace('Rp','')))
                }
            }else{
                let valRevNew = Number.parseFloat(valRev).toFixed(this.fractionDigits)
                let valFormat;

                if (valRevNew.toString() != 'NaN'){
                    valFormat = new Number(valRevNew).toLocaleString('id-ID',options)
                }else{
                    valFormat = 'NaN'
                }

                this.autoNumerics.nativeElement.value = valFormat.toString().replace('Rp','').replace(/\u00a0/g, '') //show to display
                this.modelAutoNumeric = valFormat.toString().replace('Rp','').replace(/\u00a0/g, '') //setter

                this.invalidNumber = valFormat.toString() == 'NaN' ? true : false
                if (attr == 'change'){
                    if (this.canNegativeNumber) {
                        this.changeNumeric.emit(Number(valFormat.replace(/\./g,'').replace(',','.').replace('Rp','').replace(/\u00a0/g, '').trim()))
                    } else {
                        this.changeNumeric.emit(Number(valFormat.replace(/\./g,'').replace(',','.').replace('Rp','').trim()))
                    }
                }
            }
        }
    }

    clearNumeric(event){
        this.isFocusable = true
        let val = event.target.value.replace(/\./g,'').replace('Rp','').replace(/\u00a0/g, '')
        this.autoNumerics.nativeElement.value = val.toString()
        this.autoNumerics.nativeElement.setSelectionRange(0,event.target.value.toString().length)
    }

    keypressNumeric(event){
        let value = this.autoNumerics.nativeElement.value
        let selectStart = this.autoNumerics.nativeElement.selectionStart

        let eventCode = event.key.charCodeAt(0)

        if (eventCode >= 48 && eventCode <= 57){
            let posisiDecimal = value.indexOf(",")
            let realNumber:any;

            if (window.getSelection().toString() == ""){
                if (posisiDecimal != -1){
                    if (selectStart <= posisiDecimal){
                        realNumber = value.substr(0,posisiDecimal)
                        if (realNumber.length >= this.dataTypeServices.sc_amount){
                            return false
                        }
                    }
                }else{
                    if (value.length >= this.dataTypeServices.sc_amount){
                        return false
                    }
                }
            }
        }

        if (eventCode == 44){
            if(window.getSelection().toString() == this.autoNumerics.nativeElement.value.toString()){
                return true
            }else{
                if (this.fractionDigits > 0){
                    if (value.indexOf(",") != -1){
                        return false
                    }
                }else{
                    return false
                }
            }
        } else if (eventCode == 46 || (!((eventCode >= 48 && eventCode <= 57) || (eventCode == 45 && this.canNegativeNumber)))){
                return false
        } else if (eventCode >= 48 && eventCode <= 57){
            if (value.indexOf(",") != -1){
                if (window.getSelection().toString() == ""){
                    if (value.slice(value.indexOf(",")+1).length >= this.fractionDigits){
                        if (selectStart > (value.indexOf(","))){
                            return false
                        }
                    }
                }
            }
        } else if (this.canNegativeNumber && (eventCode >= 48 && eventCode <= 57) && eventCode == 45) {
            if (value.indexOf(",") != -1){
                if (window.getSelection().toString() == ""){
                    if (value.slice(value.indexOf(",")+1).length >= this.fractionDigits){
                        if (selectStart > (value.indexOf(","))){
                            return false
                        }
                    }
                }
            }
        }
    }

    ngOnChanges(){
        let valueInterval = setInterval(()=>{
            if (!this.firstLoad){
                this.autoNumeric(this.modelAutoNumeric,'change')
                clearInterval(valueInterval)
            }
        })
    }
}
