<div class="textarea-group" [ngClass]="{'required': isRequired}">  
    <textarea 
        [id]="'textarea-id-' + textareaId"
        [name]="textareaName"
        required=""
        placeholder=" "
        class="textarea-field"
        [ngClass]="{'error-field' : isRequired && formControl.dirty && errorMessage!=''}"
        [rows]="lastRowNumber"
        [maxLength]="maxLengthValue"
        [ngStyle]="{'resize': isResizeable ? 'vertical' : 'none'}"
        [value]="textareaValue"
        (focus)="updateLastRowNumber('focus', $event)"
        (blur)="updateLastRowNumber('blur', $event)"
        (input)="updateValue($event)"
        [formControl]="formControl"
        #textarea>
    </textarea>
    <label class="form-label-title">{{ labelName | translate }}</label>
</div>
<span>
    <small [ngClass]="errorMessage!='' && isRequired && formControl.dirty ? 'd-block' : 'd-none'" class="text-error">{{errorMessage}}</small>
</span>

<!-- Example For Input in HTML -->
<!-- <textarea-component
    [labelName]="'Remark'"
    [textareaValue]="modelRemark"
    [rowNumber]="6"
    [maxLength]="dataTypeServices.sc_description"
    [classList]="'form-control'" //optional
    [isDisabled]="readOnlyInput"
    [isResizeable]="false"
    [isRequred]="false"
    [isAutoFocus]="false"
    [isModelModal]="false">
</textarea-component> -->

<!-- Notes: isModelModal need to be true if it is modal-->