<div class = 'form-group' [ngClass]="{'required': isRequired}">
    <div class="input-group" *ngIf="type=='text'">
        <input 
                required="" 
                class="input-field" 
                [ngClass]="{'error-field' : isRequired && formControl.dirty && errorMessage!=''}" 
                [id]="id" 
                [type]="type" 
                [name]="name" 
                autocomplete="off" 
                [value]="value" 
                [maxLength]="maxLength" 
                (keyup)="change($event)" 
                placeholder=" " 
                [formControl]="formControl" 
                #inputText>
        <label class="form-label-name form-control-label" [for]="name">{{label | translate }}</label>
    </div>
    <div class="input-group" *ngIf="type=='number'">
        <input  required="" 
                class="input-field"
                [ngClass]="{'error-field' : isRequired && formControl.dirty && errorMessage!=''}"
                [id]="id" 
                [type]="type" 
                [name]="name" 
                autocomplete="off" 
                [value]="value" 
                [maxLength]="maxLength" 
                (keyup)="change($event)" 
                (wheel)="wheel()" 
                oninput="if(this.value.length > this.maxLength) {this.value = '9'.repeat(this.maxLength)}" 
                [min]="min" 
                [step]="step" 
                (keypress)="inpNum($event)" 
                placeholder=" "
                [formControl]="formControl" 
                #inputNumber>
        <label class="form-label-name form-control-label">{{ label | translate }}</label>
    </div>
    
    <span>
        <small [ngClass]="errorMessage!='' && isRequired && formControl.dirty ? 'd-block' : 'd-none'" class="text-error">{{errorMessage}}</small>
    </span>
</div>
