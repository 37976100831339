<div class="applicant-hamburger-wrapper" *ngIf="hamburgerMenuFor=='Applicant'">
    <div *ngFor="let menu of menuApplicant; let i = index" class="applicant-front-list" #menu>
        <div (click)="navigateProc(menu, i)">
            <span [ngClass]="{'active-applicant-front-item' : menuActive==menu}">
                {{ menu | translate }}
            </span>
        </div>
    </div>
</div>

<div class="employee-hamburger-wrapper" *ngIf="hamburgerMenuFor=='Employee'">
    <div *ngFor="let menuEmployee of menuEmployee_Final | slice:0:7; let i = index"
        class="employee-front-list" #menu>
        <div (click)='navigateProc(menuEmployee,i)'>
            <span [ngClass]="{'active-employee-front-item' : menuActive==menuEmployee}">
                {{ menuEmployee | translate }}
            </span>
        </div>
    </div>
    <div class="more-btn" (click)="toggleProc()">
        <img src="assets/svg/employee-more-group.svg">
        <span>{{ 'More' | translate }}</span>
    </div>
</div>

<div class="employee-dropdown-list" *ngIf="hamburgerMenuFor=='Employee'">
    <div class='dropdown-menu' [ngClass]="{'show':aktif}">
        <div class="employee-dropdown-menu-list">
            <div *ngFor="let menuEmployee of menuEmployee_Final; let i = index">
                <div (click)='navigateProc(menuEmployee,i)'>
                    <span [ngClass]="{'active-employee-dropdown-item' : menuActive==menuEmployee}">
                        {{ menuEmployee | translate }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>