<style>
    .title-head {
        border:0px solid red;
        position:absolute;
        width:150px;
        height:50px;
        overflow:hidden;
        line-height:normal;
    }
    .title-text{
        height:40px;
        font-size:14px;
        font-style:normal;
        font-weight: 600;
        font-family:"Open Sans SemiBold", "Open Sans Regular", "Open Sans", sans-serif        
    }
    .value-text{
        font-weight:600;
        font-style:normal; 
        font-size:25px; 
        text-decoration: underline;
        font-family: "Open Sans SemiBold", "Open Sans Regular", "Open Sans", sans-serif;
        cursor: pointer
    }
</style>
<div class = 'd-flex justify-content-center' *ngIf="showTalentMatrix">

    <div style = 'overflow:auto'>
        <div class = 'd-flex justify-content-center'>
            <div style = 'border:0px solid blue;z-index:10;margin-right:15px;width:50px'>
                <div style = 'position:relative;top:50%;transform:translateY(-40%) rotate(-90deg);border:0px solid yellow'> 
                    <div>
                        <div class = 'd-flex justify-content-center' 
                            style = 'font-weight:700;font-size:25px;font-family:"Arial Bold", "Arial", sans-serif;
                                    font-style:normal'>{{ 'Potential' | translate }}</div>
                        <div class = 'd-flex justify-content-center'>
                            <img src = "assets/img/brand/tmpotential.svg">
                        </div>
                    </div>
                </div>
            </div>

            <div class = 'p-0' style = 'position:relative;height:450px;width:500px;overflow:auto'>
                
                <div style = 'line-height:0;width:500px'>
                    <div [ngStyle]="{background: objInput.position_7['boxColor']}" style = 'position:relative;width:150px;height:150px;display:inline-block'>
                        
                        <div class = ' mt-3 text-center text-white d-flex justify-content-center align-items-center
                                    title-head'>
                            <div class = 'title-text'>{{ objInput.position_7['boxName'] | translate }}</div>
                        </div>
                        <div style = 'position: absolute;margin-top:85px;width:150px' 
                            class = 'text-white d-flex justify-content-center'>
                            <span class = 'value-text' *ngIf="objInput.position_7['boxName']!=null && objInput.position_7['boxName']!=''"
                                (click)="showProc(objInput.position_7['boxNo'])">{{ objInput.position_7['totalEmployee'] }}</span>
                        </div>

                    </div>
                    <div [ngStyle]="{background:objInput.position_8['boxColor']}" style = 'width:150px;height:150px;display:inline-block'>

                        <div class = ' mt-3 text-center text-white d-flex justify-content-center align-items-center
                                    title-head'>
                            <div class = 'title-text'>{{ objInput.position_8['boxName'] | translate }}</div>
                        </div>
                        <div style = 'position: absolute;margin-top:85px;width:150px' 
                            class = 'text-white d-flex justify-content-center'>
                            <span class = 'value-text' *ngIf="objInput.position_8['boxName']!=null && objInput.position_8['boxName']!=''"
                                (click)="showProc(objInput.position_8['boxNo'])">{{ objInput.position_8['totalEmployee'] }}</span>
                        </div>

                    </div>
                    <div [ngStyle]="{background:objInput.position_9['boxColor']}" style = 'width:150px;height:150px;display:inline-block'>

                        <div class = ' mt-3 text-center text-white d-flex justify-content-center align-items-center
                                    title-head'>
                            <div class = 'title-text'>{{ objInput.position_9['boxName'] | translate }}</div>
                        </div>
                        <div style = 'position: absolute;margin-top:85px;width:150px' 
                            class = 'text-white d-flex justify-content-center'>
                            <span class = 'value-text' *ngIf="objInput.position_9['boxName']!=null && objInput.position_9['boxName']!=''"
                                (click)="showProc(objInput.position_9['boxNo'])">{{ objInput.position_9['totalEmployee'] }}</span>
                        </div>

                    </div>

                </div>
                <div style = 'line-height:0;width:500px'>
                    <div [ngStyle]="{background:objInput.position_6['boxColor']}" style = 'width:150px;height:150px;background:rgba(238, 201, 75, 1);display:inline-block'>
                        
                        <div class = ' mt-3 text-center text-white d-flex justify-content-center align-items-center
                                    title-head'>
                            <div class = 'title-text'>{{ objInput.position_6['boxName'] | translate }}</div>
                        </div>
                        <div style = 'position: absolute;margin-top:85px;width:150px' 
                            class = 'text-white d-flex justify-content-center'>
                            <span class = 'value-text' *ngIf="objInput.position_6['boxName']!=null && objInput.position_6['boxName']!=''"
                                (click)="showProc(objInput.position_6['boxNo'])">{{ objInput.position_6['totalEmployee'] }}</span>
                        </div>

                    </div>
                    <div [ngStyle]="{background:objInput.position_5['boxColor']}" style = 'width:150px;height:150px;display:inline-block'>

                        <div class = ' mt-3 text-center text-white d-flex justify-content-center align-items-center
                                    title-head'>
                            <div class = 'title-text'>{{ objInput.position_5['boxName'] | translate }}</div>
                        </div>
                        <div style = 'position: absolute;margin-top:85px;width:150px' 
                            class = 'text-white d-flex justify-content-center'>
                            <span class = 'value-text' *ngIf="objInput.position_5['boxName']!=null && objInput.position_5['boxName']!=''"
                                (click)="showProc(objInput.position_5['boxNo'])">{{ objInput.position_5['totalEmployee'] }}</span>
                        </div>

                    </div>
                    <div [ngStyle]="{background:objInput.position_4['boxColor']}" style = 'width:150px;height:150px;display:inline-block'>
                        
                        <div class = ' mt-3 text-center text-white d-flex justify-content-center align-items-center
                                    title-head'>
                            <div class = 'title-text'>{{ objInput.position_4['boxName'] | translate }}</div>
                        </div>
                        <div style = 'position: absolute;margin-top:85px;width:150px' 
                            class = 'text-white d-flex justify-content-center'>
                            <span class = 'value-text' *ngIf="objInput.position_4['boxName']!=null && objInput.position_4['boxName']!=''"
                                (click)="showProc(objInput.position_4['boxNo'])">{{ objInput.position_4['totalEmployee'] }}</span>
                        </div>

                    </div>
                </div>
                <div style = 'line-height:0;width:500px'>
                    <div [ngStyle]="{background:objInput['position_1']['boxColor']}" style = 'width:150px;height:150px;display:inline-block'>

                        <div class = ' mt-3 text-center text-white d-flex justify-content-center align-items-center
                                    title-head'>
                            <div class = 'title-text'>{{ objInput.position_1['boxName'] | translate }}</div>
                        </div>
                        <div style = 'position: absolute;margin-top:85px;width:150px' 
                            class = 'text-white d-flex justify-content-center'>
                            <span class = 'value-text' *ngIf="objInput.position_1['boxName']!=null && objInput.position_1['boxName']!=''"
                                (click)="showProc(objInput.position_1['boxNo'])">{{ objInput['position_1']['totalEmployee'] }}</span>
                        </div>

                    </div>
                    <div [ngStyle]="{background:objInput.position_2['boxColor']}" style = 'width:150px;height:150px;display:inline-block'>

                        <div class = ' mt-3 text-center text-white d-flex justify-content-center align-items-center
                                    title-head'>
                            <div class = 'title-text'>{{ objInput.position_2['boxName'] | translate }}</div>
                        </div>
                        <div style = 'position: absolute;margin-top:85px;width:150px' 
                            class = 'text-white d-flex justify-content-center'>
                            <span class = 'value-text' *ngIf="objInput.position_2['boxName']!=null && objInput.position_2['boxName']!=''"
                                (click)="showProc(objInput.position_2['boxNo'])">{{ objInput.position_2['totalEmployee'] }}</span>
                        </div>

                    </div>
                    <div [ngStyle]="{background:objInput.position_3['boxColor']}" style = 'width:150px;height:150px;display:inline-block'>

                        <div class = ' mt-3 text-center text-white d-flex justify-content-center align-items-center
                                    title-head'>
                            <div class = 'title-text'>{{ objInput.position_3['boxName'] | translate }}</div>
                        </div>
                        <div style = 'position: absolute;margin-top:85px;width:150px' 
                            class = 'text-white d-flex justify-content-center'>
                            <span class = 'value-text' *ngIf="objInput.position_3['boxName']!=null && objInput.position_3['boxName']!=''"
                                (click)="showProc(objInput.position_3['boxNo'])">{{ objInput.position_3['totalEmployee'] }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div style = 'position:relative;border:0px solid yellow;margin-top:7px'> 
                <div class = 'ml-1'>
                    <div class = 'd-flex justify-content-center'>
                        <img src = "assets/img/brand/tmpotential.svg">
                    </div>
                    <div class = 'd-flex justify-content-center' 
                            style = 'font-weight:700;font-size:25px;font-family:"Arial Bold", "Arial", sans-serif;
                                    font-style:normal'>{{ 'Performance' | translate }}
                    </div>
                </div>
            </div>                                    
        </div>
    </div>
</div>