<style>
    .invalidNumberStyle{
        border:1px solid var(--git-danger)
    }
    .currencyLabelRadBorder{
        border-left:none; 
        border-top-left-radius: 0; 
        border-bottom-left-radius: 0;
    }
</style>

<div class ='form-group' [ngClass]="showCurrencyLabel ? 'd-flex' : ''">
    <div class="w-auto" style="border-top-right-radius: 0; border-bottom-right-radius: 0; background-color:#E9ECEF;"
        [ngStyle]="{'border': isFocusable ? '1px solid blue' : '1px solid #dee2e6'}" *ngIf="showCurrencyLabel">
        <div style="margin-top:0.15rem">{{ currencyLabel }}</div>
    </div>
    <input type = 'text' class = ''
        (change)="autoNumeric($event,'change')"
        (blur)="autoNumeric($event,'change')"
        (focus)="clearNumeric($event)"
        [ngClass] = '{invalidNumberStyle:invalidNumber, "currencyLabelRadBorder": showCurrencyLabel,
                "form-control-sm": sizeForm == "sm", "form-control-lg": sizeForm == "lg"}'
        #autoNumerics
        placeholder=" "
        (keypress)="keypressNumeric($event)"
        [disabled]="isDisabled"
        >
    <label class = 'form-input-label'>{{ placeholderLabel | translate }}</label>
</div>
