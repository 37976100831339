<style>
    button.try-again {
        background-color: #0085FF;
    }
    button.try-again:active{
        background-color:#005bb1;
    }
</style>
<!-- left:-0.3px; top: -1px -->
<div style = "background:var(--git-retry-error-bg);box-shadow:4px 4px 4px rgba(0, 0, 0, 0.25);
        position: absolute;z-index:98;"
    [ngStyle]="{height: height, width: width, 'border-radius': borderRadius }"
        class = 'd-flex flex-column align-items-center justify-content-center text-center px-3'>
    <img [src] = "methodServices.statusDark ? 
                      '../../../../assets/img/emoticon/tryagain-emoticon-dark.svg' 
                    : '../../../../assets/img/emoticon/tryagain-emoticon.svg'" 
        height = "60" width= "60">
    <span class = "my-2" style = "font-family: Arial;font-size:15px;font-weight:bold;line-height:normal">{{ message }}</span>
    <button class = 'try-again btn d-flex align-items-center justify-content-center mt-1' 
            style = "color:white;font-family:Arial, Helvetica, sans-serif;
                    font-weight:lighter;font-size:14px;border-radius:20px;height:30px;"
            (click)="clickProc($event)"
        >
        <span class = 'btn-inner--text'><u>{{ 'Try Again' | translate }}</u></span>
    </button>
</div>
<div></div>