import { Component, OnInit, AfterViewInit, Input, EventEmitter, Output } from '@angular/core';
import { MethodServices } from 'src/services/method-services';

@Component({
    selector:'git-range-datepicker',
    templateUrl:'git-range-datepicker.html',
    styleUrls:['git-range-datepicker.scss']
})

export class gitRangeDatepicker implements OnInit, AfterViewInit {
    @Output() changeDateRangeValue = new EventEmitter<any>()
    //Notes: in front component need to add modelRangePicker dengan bentuk array 
    //ex: modelRangePicker = [new Date("2024-01-10"), new Date("2024-01-30")]
    @Input() modelRangePicker:any;  
    @Input() gitClass:string;
    @Input() gitRequired:boolean = false;
    @Input() gitFormatDate:string;
    @Input() gitLabel:string;
    @Input() minDate?:any;
    @Input() maxDate?:any;
    @Input() isDisabled:boolean = false;
    @Input() rangePickerMaxDate:number = 59
    arrFilterHeader: any[] = []
    isInvalidDate: boolean = false
    placeholderRangePicker:any = " "

    firstLoad: boolean = true
    isDarkMode:boolean = false

    constructor(private methodServices: MethodServices) {
        this.methodServices.subject_DarkMode.subscribe((result)=>{
            this.methodServices.statusDark = result
            this.isDarkMode = result
        })
    }

    ngOnInit() {
       
    }

    ngAfterViewInit() {
        this.firstLoad = false
    }

    changeDateFilter(event) {
        if (!this.firstLoad) {
            if (event instanceof KeyboardEvent)
                this.changeDateFilter(null)
            else {
                if (event==undefined){
                    this.isInvalidDate = false
                    this.placeholderRangePicker = " "
                    this.changeDateRangeValue.emit("")
                } else if (typeof event=="object" && event[0]==undefined) {
                    this.isInvalidDate = true
                    this.placeholderRangePicker = "Invalid date"
                    this.modelRangePicker = "Invalid Date"
                    this.changeDateRangeValue.emit("Invalid Date")
                } else {
                    this.isInvalidDate = false
                    this.placeholderRangePicker = " "
                    this.changeDateRangeValue.emit(event)
                }
            }
        }
    }
}
