<div class="modal fade" bsModal #modalUploadingDialog="bs-modal" (onHidden)="handler('onHidden', $event)" (onShown)="handler('onShown', $event)" [config]="{keyboard:false, animated:true,ignoreBackdropClick:true}" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class=" modal-header">
                <div class = 'w-100'>
                    <div style="width: 100%;" class = 'd-flex justify-content-between'>
                        <h6 class=" modal-title mb-1" id="modal-title-default">
                            {{ 'Progress' | translate }}
                        </h6>
                    </div>
                    <hr style = 'border:1px solid lightsteelblue' class = 'mt-1'>
                </div>
            </div>

            <div class=" modal-body mt--6">
                <div class = 'row'>
                    <div class = 'col-12'>
                        <div class="container-files">
                            <div *ngFor="let data of fileData">
                                <div class="item-files" *ngIf="data.progress !== undefined">
                                    <div class="mx-3 icon-file">
                                        <i class="fa fa-file-alt"></i>
                                    </div>
                                    <div class="file-progress-info">
                                        <small>{{data.name + ' - ' + data.fileSizeLabel}}</small>
                                        <progressbar [animate]="true" [striped]="true" [value]="data.progress" [max]="100" [type]="data.status"></progressbar>
                                        <small *ngIf="data.status == 'info'"><em>{{data.msgStatus}}</em></small>
                                        <small class="text-success" *ngIf="data.status == 'success'"><em>{{data.msgStatus}}</em></small>
                                        <small class="text-danger" *ngIf="data.status == 'error'"><em>{{data.msgStatus}}</em></small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class=" modal-footer mt--5">
                <button *ngIf="true"
                        class="btn btn-link ml-auto"
                        type="button"
                        (click)="modalUploadingDialog.hide()">
                    {{ 'Close' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
