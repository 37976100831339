<nav class="navbar navbar-top navbar-expand navbar-dark border-bottom nav-git-custom">
    <div
        class="container-fluid"
        style="padding: 0 30px; height: 100%; width: 100%; align-items: start"
    >
        <div
            class="collapse navbar-collapse"
            id="navbarSupportedContent"
        >
            <ul
                class="navbar-nav mr-auto"
                style="z-index: 10"
            >
                <li
                    class="nav-item"
                    id="nav-brand"
                >
                    <!-- Sidenav toggler -->
                    <div
                        [ngClass]="skeletonIcon ? 'd-block' : 'd-none'"
                        class="card card-stats skeleton"
                        style="z-index: 1200 !important; width: 406px; height: 70px; position: absolute; left: 1.5rem; border-radius: 15rem; margin-bottom: 0 !important"
                    >
                        <div
                            class="card-body d-flex"
                            style="height: 70px; border-radius: 15rem; gap: 1rem"
                        >
                            <ngx-skeleton-loader
                                animation="progress-dark"
                                [theme]="{ 'border-radius': '50%', width: '40px', height: '40px' }"
                            >
                            </ngx-skeleton-loader>
                            <ngx-skeleton-loader
                                animation="progress-dark"
                                [theme]="{ 'border-radius': '50%', width: '40px', height: '40px' }"
                            >
                            </ngx-skeleton-loader>
                            <ngx-skeleton-loader
                                animation="progress-dark"
                                [theme]="{ height: '30px' }"
                                style="width: 75%; margin-top: 5px"
                            >
                            </ngx-skeleton-loader>
                        </div>
                    </div>
                    <div
                        class="pr-0 btn-toggle-navbar sidenav-toggler-dark"
                        [ngClass]="!skeletonIcon ? 'd-block' : 'd-none'"
                    >
                        <label
                            class="hamburger"
                            [ngStyle]="{ cursor: disabledSideMenu ? 'default' : 'pointer', opacity: disabledSideMenu ? '0.8' : '1' }"
                        >
                            <input
                                type="checkbox"
                                [checked]="!sidenavOpen"
                                [disabled]="disabledSideMenu"
                            />
                            <svg
                                viewBox="0 0 32 32"
                                (click)="openSidebar()"
                            >
                                <path
                                    class="line line-top-bottom"
                                    d="M27 10 13 10C10.8 10 9 8.2 9 6 9 3.5 10.8 2 13 2 15.2 2 17 3.8 17 6L17 26C17 28.2 18.8 30 21 30 23.2 30 25 28.2 25 26 25 23.8 23.2 22 21 22L7 22"
                                ></path>
                                <path
                                    class="line"
                                    d="M7 16 27 16"
                                ></path>
                            </svg>
                        </label>
                    </div>
                    <div
                        class="navbar-brand"
                        [routerLink]="['home/profile']"
                        style="cursor: pointer"
                    >
                        <div class="d-flex justify-content-center align-items-center h-100 container-brand">
                            <!-- <img src="assets/img/brand/logo-left.png" style="width:50%; height: 50%;" alt="..." /> -->
                            <img
                                [ngClass]="methodServices.imageLoad ? 'd-block' : 'd-none'"
                                [src]="methodServices.companyLogo"
                                style="width: auto; height: 75%"
                                alt=""
                                (load)="loadNavbarIcon($event)"
                                #nativeIcon
                            />
                        </div>
                    </div>
                </li>
            </ul>

            <ul
                class="navbar-nav ml-auto align-items-center"
                style="z-index: 1002"
            >
                <!-- FAQ -->
                <li
                    class="nav-item dropdown custom-faq p-1"
                    dropdown
                >
                    <div
                        class="dropdown-toggle"
                        role="button"
                        dropdownToggle
                    >
                        <img
                            src="../../../assets/img/png/faq-logo.png"
                            alt="faq-logo"
                            style="width: 40px; height: 40px; margin-right: -4px"
                        />
                    </div>
                    <div
                        class="dropdown-menu"
                        *dropdownMenu
                    >
                        <a
                            class="dropdown-item"
                            target="_blank"
                            href="https://starconnect.id/panduan-penggunaan/"
                        >
                            <span style="width: 20px; height: 20px">
                                <svg
                                    width="20"
                                    height="17"
                                    viewBox="0 0 20 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M10 4.75C10.625 2.28282 12.9856 1.02305 18.125 1C18.2072 0.999695 18.2886 1.01565 18.3645 1.04695C18.4405 1.07825 18.5095 1.12427 18.5676 1.18237C18.6257 1.24047 18.6718 1.30949 18.7031 1.38546C18.7344 1.46143 18.7503 1.54284 18.75 1.625V12.875C18.75 13.0408 18.6842 13.1997 18.5669 13.3169C18.4497 13.4342 18.2908 13.5 18.125 13.5C13.125 13.5 11.1934 14.5082 10 16M10 4.75C9.375 2.28282 7.01446 1.02305 1.875 1C1.79284 0.999695 1.71143 1.01565 1.63546 1.04695C1.55949 1.07825 1.49047 1.12427 1.43237 1.18237C1.37427 1.24047 1.32825 1.30949 1.29695 1.38546C1.26565 1.46143 1.24969 1.54284 1.25 1.625V12.7996C1.25 13.1856 1.48907 13.5 1.875 13.5C6.875 13.5 8.81368 14.5156 10 16M10 4.75V16"
                                        stroke="var(--git-field-label)"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </span>
                            <span>
                                {{ "Guide" | translate }}
                            </span>
                        </a>
                        <a
                            class="dropdown-item"
                            target="_blank"
                            href="https://www.youtube.com/watch?v=Dc16wPASKKA"
                        >
                            <span style="width: 20px; height: 20px">
                                <svg
                                    width="20"
                                    height="15"
                                    viewBox="0 0 20 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M17.4997 0H2.49967C1.57467 0 0.833008 0.741667 0.833008 1.66667V11.6667C0.833008 12.1087 1.0086 12.5326 1.32116 12.8452C1.63372 13.1577 2.05765 13.3333 2.49967 13.3333H6.66634V15H13.333V13.3333H17.4997C18.4163 13.3333 19.158 12.5833 19.158 11.6667L19.1663 1.66667C19.1663 1.22464 18.9907 0.800716 18.6782 0.488155C18.3656 0.175595 17.9417 0 17.4997 0ZM17.4997 11.6667H2.49967V1.66667H17.4997V11.6667ZM13.333 6.66667L7.49967 10V3.33333L13.333 6.66667Z"
                                        fill="var(--git-field-label)"
                                    />
                                </svg>
                            </span>
                            <span>
                                {{ "Video" | translate }}
                            </span>
                        </a>
                    </div>
                </li>

                <!-- CHATBOT -->
                <li class="nav-item mx-2" *ngIf="synthiaAuthority">
                    <div
                        (click)="openChatbot()"
                        style="margin-right: -6px"
                        [ngStyle]="{ cursor: !methodServices.imageLoad ? 'default' : 'pointer' }"
                    >
                        <img
                            src="../../../assets/svg/chatbot.svg"
                            alt="chatbot-icon"
                            style="width: 32px; height: 32px"
                        />
                    </div>
                </li>

                <!-- DARKMODE -->
                <li class="nav-item mx-2">
                    <div>
                        <input
                            type="checkbox"
                            class="checkbox"
                            id="checkbox"
                            [checked]="darkModeChecked"
                            #nativeToggleDarkMode
                        />
                        <label
                            for="checkbox"
                            class="label"
                            (click)="changeDarkMode($event)"
                        >
                            <img
                                src="../../../assets/img/png/icons8-moon-100.png"
                                class="img-moon"
                                title="Dark Mode"
                                *ngIf="darkModeIsChecked"
                            />
                            <img
                                src="../../../assets/img/png/icons8-sun-64.png"
                                class="img-sun"
                                title="Light Mode"
                                *ngIf="!darkModeIsChecked"
                            />
                        </label>
                    </div>
                </li>

                <!-- LANGUAGE -->
                <li
                    class="nav-item dropdown text-white custom-language p-1"
                    (isOpenChange)="onOpenChange($event)"
                    dropdown
                >
                    <div
                        class="dropdown-toggle"
                        role="button"
                        dropdownToggle
                    >
                        <span style="border: 0px solid red; position: relative; display: block; height: 30px; width: 30px">
                            <img
                                src="../../../assets/img/flags/{{ selectedLangCode }}.png"
                                class="mr-0"
                                [title]="selectedLang | lowercase | translate"
                                style="position: relative; height: 100%; width: 100%; border-radius: 50%"
                            />
                        </span>
                    </div>
                    <div
                        class="dropdown-menu mt-2"
                        *dropdownMenu
                    >
                        <a
                            class="dropdown-item"
                            *ngFor="let langLoop of langLoop"
                            (click)="funcChangeLang(langLoop.flag)"
                        >
                            <img
                                src="../../../assets/img/flags/{{ langLoop.flag }}.png"
                                width="30"
                                height="20"
                                class="mr-1"
                            />
                            {{ langLoop.lang | translate }}
                        </a>
                    </div>
                </li>

                <li
                    class="nav-item dropdown employee-avatar-top d-flex justify-content-center align-items-center"
                    dropdown
                    placement="bottom-right"
                >
                    <a
                        class="nav-link pr-0 dropdown-toggle pl-3"
                        role="button"
                        dropdownToggle
                    >
                        <div class="media align-items-center">
                            <div class="d-flex mt-lg-0 align-items-center">
                                <span
                                    class="avatar avatar-md rounded-circle"
                                    [ngStyle]="{ 'background-color': backgroundAvatar, border: '1px solid rgb(70, 70, 70)' }"
                                >
                                    <span
                                        style="cursor: pointer"
                                        class="text-xl"
                                        *ngIf="employeeImage == null"
                                        >{{ employeeInitial }}</span
                                    >
                                    <img
                                        [src]="employeeImage"
                                        *ngIf="employeeImage != null"
                                        alt="user image"
                                        height="50"
                                        style="width: 100%; object-fit: cover"
                                    />
                                </span>
                                <div
                                    class="font-weight-bold pr-0 ml-3"
                                    style="cursor: pointer; font-size: 1rem"
                                >
                                    <span class="employee-name">{{ employeeNameNavbar }}</span>
                                </div>
                            </div>
                        </div>
                    </a>
                    <div
                        class="dropdown-menu dropdown-menu-arrow dropdown-menu-left dropdown-menu-profile"
                        *dropdownMenu
                    >
                        <div class="dropdown-header noti-title">
                            <h6
                                class="text-overflow m-0"
                                style="overflow: hidden; text-overflow: ellipsis; color: var(--git-field-label)"
                            >
                                {{ "welcome" | translate }}, {{ employeeName }}
                            </h6>
                        </div>
                        <div class="dropdown-divider"></div>
                        <a
                            href="javascript:void(0)"
                            class="dropdown-item"
                            (click)="switchUser()"
                            style="color: var(--git-field-label)"
                        >
                            <svg
                                width="20"
                                height="15"
                                viewBox="0 0 20 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M9.74423 10.5011C11.4017 10.5011 12.7453 9.15744 12.7453 7.5C12.7453 5.84255 11.4017 4.49889 9.74423 4.49889C8.08679 4.49889 6.74312 5.84255 6.74312 7.5C6.74312 9.15744 8.08679 10.5011 9.74423 10.5011ZM9.74423 10.8346C11.5859 10.8346 13.0788 9.34167 13.0788 7.5C13.0788 5.65832 11.5859 4.16544 9.74423 4.16544C7.90255 4.16544 6.40967 5.65832 6.40967 7.5C6.40967 9.34167 7.90255 10.8346 9.74423 10.8346Z"
                                    fill="var(--git-field-label)"
                                />
                                <path
                                    d="M7.74365 9.43904C7.74365 9.26681 7.87237 9.12125 8.04376 9.10225C9.33007 8.95986 10.1645 8.9727 11.448 9.10541C11.5121 9.11214 11.5729 9.13739 11.6228 9.17808C11.6728 9.21876 11.7099 9.27312 11.7294 9.33452C11.749 9.39591 11.7503 9.46168 11.7332 9.5238C11.716 9.58591 11.6811 9.64168 11.6328 9.68429C10.118 11.0046 9.25237 10.9864 7.85036 9.68563C7.782 9.62227 7.74365 9.53207 7.74365 9.43904Z"
                                    fill="var(--git-field-label)"
                                />
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M11.4311 9.27114C10.1578 9.13943 9.3353 9.12709 8.06216 9.26798C8.02025 9.27286 7.98162 9.29303 7.95365 9.32462C7.92569 9.35622 7.91036 9.39701 7.91061 9.4392C7.91061 9.48689 7.93045 9.53207 7.96396 9.56342C8.65888 10.208 9.18541 10.4993 9.7001 10.5011C10.2166 10.5029 10.7715 10.214 11.5234 9.55875C11.5473 9.53749 11.5645 9.50974 11.5729 9.47889C11.5814 9.44804 11.5806 9.4154 11.5708 9.38496C11.5611 9.35451 11.5426 9.32756 11.5178 9.30738C11.493 9.28721 11.4629 9.27451 11.4311 9.27114ZM8.02565 8.93652C9.32546 8.79263 10.1721 8.80564 11.4656 8.93952C11.562 8.94958 11.6534 8.98754 11.7286 9.04875C11.8037 9.10996 11.8595 9.19175 11.8889 9.28413C11.9183 9.3765 11.9201 9.47545 11.8941 9.56885C11.8682 9.66225 11.8155 9.74606 11.7427 9.81001C10.9799 10.4749 10.3447 10.8371 9.6991 10.8346C9.05169 10.8322 8.44447 10.4639 7.73738 9.80784C7.68675 9.76068 7.64639 9.7036 7.61882 9.64014C7.59125 9.57668 7.57707 9.50822 7.57715 9.43904C7.57691 9.31473 7.62253 9.1947 7.70529 9.10195C7.78805 9.00919 7.90212 8.9504 8.02565 8.93652Z"
                                    fill="var(--git-field-label)"
                                />
                                <path
                                    d="M11.0778 6.83309C11.0778 7.18684 10.9373 7.52611 10.6871 7.77625C10.437 8.02639 10.0977 8.16692 9.74398 8.16692C9.39023 8.16692 9.05096 8.02639 8.80082 7.77625C8.55068 7.52611 8.41016 7.18684 8.41016 6.83309C8.41016 6.47934 8.55068 6.14008 8.80082 5.88994C9.05096 5.6398 9.39023 5.49927 9.74398 5.49927C10.0977 5.49927 10.437 5.6398 10.6871 5.88994C10.9373 6.14008 11.0778 6.47934 11.0778 6.83309Z"
                                    fill="var(--git-field-label)"
                                />
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M9.74398 7.83346C10.0093 7.83346 10.2637 7.72806 10.4513 7.54046C10.639 7.35285 10.7443 7.09841 10.7443 6.83309C10.7443 6.56778 10.639 6.31333 10.4513 6.12572C10.2637 5.93812 10.0093 5.83272 9.74398 5.83272C9.47867 5.83272 9.22422 5.93812 9.03661 6.12572C8.84901 6.31333 8.74361 6.56778 8.74361 6.83309C8.74361 7.09841 8.84901 7.35285 9.03661 7.54046C9.22422 7.72806 9.47867 7.83346 9.74398 7.83346ZM9.74398 8.16692C10.0977 8.16692 10.437 8.02639 10.6871 7.77625C10.9373 7.52611 11.0778 7.18684 11.0778 6.83309C11.0778 6.47934 10.9373 6.14008 10.6871 5.88994C10.437 5.6398 10.0977 5.49927 9.74398 5.49927C9.39023 5.49927 9.05096 5.6398 8.80082 5.88994C8.55068 6.14008 8.41016 6.47934 8.41016 6.83309C8.41016 7.18684 8.55068 7.52611 8.80082 7.77625C9.05096 8.02639 9.39023 8.16692 9.74398 8.16692Z"
                                    fill="var(--git-field-label)"
                                />
                                <path
                                    d="M14.2302 6.24954H19.147C19.2064 6.24956 19.2645 6.26651 19.3147 6.29839C19.3648 6.33028 19.4048 6.37578 19.43 6.42958C19.4552 6.48337 19.4645 6.54323 19.4569 6.60214C19.4493 6.66106 19.4251 6.71659 19.3871 6.76223L16.9287 9.71332C16.8993 9.7485 16.8626 9.7768 16.8211 9.79623C16.7796 9.81565 16.7344 9.82572 16.6886 9.82572C16.6428 9.82572 16.5975 9.81565 16.556 9.79623C16.5145 9.7768 16.4778 9.7485 16.4485 9.71332L13.9901 6.76223C13.9521 6.71659 13.9278 6.66106 13.9202 6.60214C13.9126 6.54323 13.922 6.48337 13.9472 6.42958C13.9724 6.37578 14.0124 6.33028 14.0625 6.29839C14.1126 6.26651 14.1708 6.24956 14.2302 6.24954ZM0.475096 8.75046H5.39191C5.45131 8.75044 5.50947 8.73349 5.55959 8.70161C5.60971 8.66972 5.64971 8.62422 5.6749 8.57043C5.70009 8.51663 5.70943 8.45677 5.70184 8.39786C5.69424 8.33894 5.67001 8.28342 5.63199 8.23777L3.17359 5.28669C3.14425 5.2515 3.10754 5.2232 3.06605 5.20378C3.02456 5.18435 2.97931 5.17429 2.9335 5.17429C2.88769 5.17429 2.84244 5.18435 2.80095 5.20378C2.75946 5.2232 2.72275 5.2515 2.69341 5.28669L0.235007 8.23777C0.196991 8.28342 0.172764 8.33894 0.165166 8.39786C0.157568 8.45677 0.166911 8.51663 0.192103 8.57043C0.217295 8.62422 0.257292 8.66972 0.30741 8.70161C0.357529 8.73349 0.415694 8.75044 0.475096 8.75046Z"
                                    fill="var(--git-field-label)"
                                />
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M9.81108 1.2477C7.87037 1.2477 6.13473 2.13177 4.98806 3.52103C4.93686 3.5874 4.87283 3.64279 4.79979 3.68392C4.72675 3.72504 4.64619 3.75106 4.56289 3.76043C4.47959 3.76979 4.39526 3.76231 4.31491 3.73843C4.23456 3.71455 4.15984 3.67476 4.09518 3.62142C4.03051 3.56808 3.97724 3.50229 3.93852 3.42794C3.89979 3.3536 3.87642 3.27223 3.86977 3.18867C3.86313 3.10512 3.87335 3.02107 3.89984 2.94155C3.92633 2.86202 3.96853 2.78863 4.02395 2.72574C4.93953 1.61724 6.15422 0.795046 7.52357 0.35694C8.89292 -0.0811654 10.3593 -0.116741 11.7483 0.254444C13.1372 0.625629 14.3904 1.38795 15.3586 2.45075C16.3269 3.51354 16.9695 4.83208 17.2101 6.24954H15.9383C15.6496 4.83753 14.8822 3.56854 13.7657 2.65715C12.6492 1.74575 11.2523 1.24787 9.81108 1.2477ZM3.68383 8.75046C3.92055 9.90666 4.47956 10.9723 5.29627 11.8243C6.11298 12.6762 7.15405 13.2797 8.29923 13.5651C9.44441 13.8504 10.6469 13.806 11.7679 13.4368C12.8889 13.0677 13.8826 12.3889 14.6341 11.479C14.6853 11.4126 14.7493 11.3572 14.8224 11.3161C14.8954 11.275 14.976 11.2489 15.0593 11.2396C15.1426 11.2302 15.2269 11.2377 15.3073 11.2616C15.3876 11.2854 15.4623 11.3252 15.527 11.3786C15.5917 11.4319 15.6449 11.4977 15.6837 11.5721C15.7224 11.6464 15.7457 11.7278 15.7524 11.8113C15.759 11.8949 15.7488 11.9789 15.7223 12.0585C15.6958 12.138 15.6536 12.2114 15.5982 12.2743C14.6826 13.3828 13.4679 14.205 12.0986 14.6431C10.7292 15.0812 9.26288 15.1167 7.8739 14.7456C6.48492 14.3744 5.23178 13.612 4.26353 12.5492C3.29527 11.4865 2.65266 10.1679 2.41211 8.75046H3.68383Z"
                                    fill="var(--git-field-label)"
                                />
                            </svg>
                            <span>{{ "switch_users" | translate }}</span>
                        </a>
                        <a
                            href="javascript:void(0)"
                            class="dropdown-item"
                            (click)="changePassword()"
                            style="color: var(--git-field-label)"
                        >
                            <i class="fas fa-key"></i> <span>{{ "change_password" | translate }}</span>
                        </a>
                        <a
                            [ngClass]="methodServices.jwt_role_user == 'SUPER_ADMIN' ? 'd-none' : ''"
                            href="javascript:void(0)"
                            class="dropdown-item"
                            (click)="changePicture()"
                            style="color: var(--git-field-label)"
                        >
                            <i class="ni ni-circle-08"></i> <span>{{ "change_picture" | translate }}</span>
                        </a>
                        <a
                            href="javascript:void(0)"
                            class="dropdown-item"
                            (click)="logout()"
                            style="color: var(--git-field-label)"
                        >
                            <i class="ni ni-user-run"></i> <span>{{ "Sign Out" | translate }}</span>
                        </a>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</nav>

<!-- MODAL FOR CHANGE PASSWORD -->
<div
    class="modal"
    bsModal
    #modalDefault="bs-modal"
    [config]="{ keyboard: false, animated: false, ignoreBackdropClick: true }"
    tabindex="-1"
    role="dialog"
>
    <div class="modal-dialog modal-dialog-centered modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <h6
                    class="modal-title"
                    id="modal-title-default-1"
                >
                    {{ "change_password" | translate }}
                </h6>
                <button
                    aria-label="Close"
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    (click)="modalDefault.hide()"
                >
                    <span
                        aria-hidden="true"
                        style="font-size: 30px"
                    >
                        ×
                    </span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row mt--4">
                    <div class="col">
                        <div class="form-group required">
                            <input
                                type="password"
                                id="current-password-modal-id"
                                class=""
                                placeholder=" "
                                name="current-password-modal-name"
                                #nativeCurrentPasswordModal
                                [value]="modelCurrentPasswordModal ? modelCurrentPasswordModal : null"
                                [disabled]="readOnlyInput"
                            />
                            <label
                                for="current-password-modal-id"
                                class="form-input-label"
                            >
                                {{ "current_password" | translate }}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row mt--3">
                    <div class="col">
                        <div class="form-group required">
                            <input
                                type="password"
                                id="new-password-modal-id"
                                class=""
                                placeholder=" "
                                name="new-password-modal-name"
                                #nativeNewPasswordModal
                                [value]="modelNewPasswordModal ? modelNewPasswordModal : null"
                                [disabled]="readOnlyInput"
                            />
                            <label
                                for="new-password-modal-id"
                                class="form-input-label"
                            >
                                {{ "new_password" | translate }}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row mt--3">
                    <div class="col">
                        <div class="form-group required">
                            <input
                                type="password"
                                id="confirm-new-password-modal-id"
                                class=""
                                placeholder=" "
                                name="confirm-new-password-modal-name"
                                #nativeConfirmNewPasswordModal
                                [value]="modelConfirmNewPasswordModal ? modelConfirmNewPasswordModal : null"
                                [disabled]="readOnlyInput"
                            />
                            <label
                                for="confirm-new-password-modal-id"
                                class="form-input-label"
                            >
                                {{ "confirm_new_password" | translate }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer d-flex justify-content-around mt--4">
                <button
                    class="btn btn-primary"
                    type="button"
                    (click)="updateItem()"
                    [disabled]="disabledButton"
                >
                    {{ "save_changes" | translate }}
                </button>
                <button
                    class="btn btn-link"
                    data-dismiss="modal"
                    type="button"
                    [disabled]="disabledButton"
                    (click)="modalDefault.hide()"
                >
                    {{ "close" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<!-- MODAL FOR CHANGE PICTURE -->
<div
    class="modal"
    bsModal
    #modalChangePicture="bs-modal"
    [config]="{ keyboard: false, animated: false, ignoreBackdropClick: true }"
    tabindex="-1"
    role="dialog"
>
    <div class="modal-dialog modal-dialog-centered modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <h6
                    class="modal-title"
                    id="modal-title-default"
                >
                    {{ "Change Picture" | translate }}
                </h6>
                <button
                    aria-label="Close"
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    (click)="modalChangePicture.hide()"
                >
                    <span
                        aria-hidden="true"
                        style="font-size: 30px"
                    >
                        ×
                    </span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row mt--4">
                    <div class="col">
                        <div class="form-group required">
                            <div
                                class="position-relative"
                                style="height: 250px; width: 250px; border-radius: 50%; left: 50%; transform: translateX(-50%)"
                                [ngStyle]="{ border: modelImage != null ? '1px solid lightgray' : '1px solid lightgray' }"
                            >
                                <input
                                    type="file"
                                    name="image-name"
                                    id="image-id"
                                    class="invisible"
                                    accept="image/*"
                                    (change)="uploadFile($event)"
                                    #nativeImage
                                />
                                <div
                                    class="position-absolute"
                                    style="left: 50%; top: 50%; transform: translate(-50%, -50%); width: 100%; height: 100%"
                                >
                                    <img
                                        [src]="modelImageReader"
                                        *ngIf="modelImage != null"
                                        style="width: 100%; height: 100%; border-radius: 50%; object-fit: cover"
                                        id="imageload-id"
                                        #nativeImageLoad
                                    />
                                </div>

                                <div
                                    class="position-absolute w-50 text-center"
                                    style="top: 50%; left: 50%; transform: translate(-50%, -50%); height: 50px"
                                >
                                    <i
                                        class="fa fa-image text-primary"
                                        *ngIf="modelImage == null"
                                        style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); font-size: 6rem"
                                    ></i>
                                </div>

                                <div
                                    class="position-absolute pt-2"
                                    style="left: 50%; top: 0; transform: translateX(-50%)"
                                >
                                    <button
                                        class="bg-success rounded-circle border d-flex justify-content-center align-items-center"
                                        style="width: 30px; height: 30px; outline: 0; float: left"
                                        (click)="nativeImage.click()"
                                        [disabled]="readOnlyInput"
                                    >
                                        <i
                                            class="fa fa-pencil-alt text-white"
                                            style="font-size: 17px"
                                        ></i>
                                    </button>
                                    <button
                                        class="bg-danger rounded-circle border d-flex justify-content-center align-items-center"
                                        style="width: 30px; height: 30px; outline: 0"
                                        (click)="clearImage()"
                                        [disabled]="readOnlyInput"
                                    >
                                        <i
                                            class="fa fa-times text-white"
                                            style="font-size: 17px"
                                        ></i>
                                    </button>
                                </div>
                            </div>
                            <div>
                                <span class="text-danger text-sm">* {{ "Maximum file size allowed is 5MB" | translate }}.</span><br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer d-flex justify-content-around mt--5">
                <button
                    class="btn btn-primary"
                    type="button"
                    (click)="submitProc()"
                    [disabled]="disabledButton"
                >
                    {{ "save_changes" | translate }}
                </button>
                <button
                    class="btn btn-link"
                    data-dismiss="modal"
                    type="button"
                    [disabled]="disabledButton"
                    (click)="modalChangePicture.hide()"
                >
                    {{ "close" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<!-- MODAL FOR CHANGE PROFILE -->
<div
    class="modal"
    bsModal
    #modalSwitchUser="bs-modal"
    [config]="{ keyboard: false, animated: false, ignoreBackdropClick: true }"
    tabindex="-1"
    role="dialog"
>
    <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <div class="hidden-content-space"></div>
                <h1 class="header-choose-profile">{{ "Choose your Profile" | translate }}</h1>
                <button
                    aria-label="Close"
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    (click)="modalSwitchUser.hide()"
                    style="margin: 0"
                >
                    <span
                        aria-hidden="true"
                        style="font-size: 30px"
                    >
                        ×
                    </span>
                </button>
            </div>
            <div class="modal-body p-0">
                <div
                    class="container-choose-user"
                    *ngFor="let data of listUsersData"
                >
                    <div
                        class="list-profile"
                        [ngClass]="data.currentProfile ? 'current-profile' : ''"
                        (click)="selectProfile(data)"
                    >
                        <div
                            class="user-profile"
                            [style]="{ 'background-color': data.backgroundAvatarColor }"
                        >
                            <img
                                [src]="data.profilePicture"
                                *ngIf="data.profilePicture != null"
                                alt="profile pic"
                            />
                            <span *ngIf="data.profilePicture == null">{{ data.alias }}</span>
                        </div>
                        <div class="user-information">
                            <span class="fullname">{{ data.fullName }}</span>
                            <span class="roles">{{ data.positionName }}</span>
                            <span class="nik">{{ data.employeeNo }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- MODAL FOR CHATBOT -->
<div
    class="modal modal-chatbot"
    bsModal
    #modalChatbot="bs-modal"
    [config]="{ backdrop: false, keyboard: false, animated: false, ignoreBackdropClick: true }"
    tabindex="-1"
    role="dialog"
>
    <div class="modal-content">
        <div class="modal-header d-flex justify-content-between">
            <div
                class="d-flex"
                style="gap: 0.25rem"
            >
                <img
                    src="../../../assets/svg/chatbot-white.svg"
                    alt="chatbot-icon"
                    style="width: 18px; height: 18px"
                />
                <h5>{{ "Synthia" }}</h5>
            </div>
            <div class="dropdown-wrapper">
                <ng-select
                    [(ngModel)]="selectedCompany"
                    [disabled]="loadingChatbotApi"
                    [clearable]="false"
                    [searchable]="false"
                    (ngModelChange)="changeCompany()"
                >
                    <ng-option
                        [value]="data.companyId"
                        *ngFor="let data of companyChatbotList; trackBy: selectedCompany"
                        >{{ data.companyName }}</ng-option
                    >
                </ng-select>
            </div>
            <button
                aria-label="Close"
                type="button"
                class="close"
                data-dismiss="modal"
                (click)="modalChatbot.hide()"
                style="margin: 0; color: white !important"
            >
                <span
                    aria-hidden="true"
                    style="font-size: 2rem"
                >
                    ×
                </span>
            </button>
        </div>
        <div
            class="modal-body"
            id="chatbot-body-container"
        >
            <div
                class="welcome-wrapper"
                *ngIf="questionAnswerList.length <= 0"
                [ngStyle]="{ background: darkModeIsChecked ? '#5555' : '#f5f5f5' }"
            >
                <h3>{{ welcomeTitle }}</h3>
                <small>{{ welcomeDesc }}</small>
            </div>

            <div *ngFor="let data of questionAnswerList; let idx = index">
                <div class="question-wrapper">
                    <div
                        class="d-flex"
                        style="gap: 0.25rem"
                    >
                        <span
                            title="Refresh"
                            class="fas fa-sync-alt reload-animation"
                            style="color: var(--git-field-label)"
                            *ngIf="data.loadChatApi"
                        ></span>
                        <span
                            title="Retry"
                            class="fas fa-sync-alt retry-icon"
                            style="color: var(--git-field-label)"
                            [ngStyle]="{ cursor: loadingChatbotApi ? 'default' : 'pointer' }"
                            [ngClass]="{ 'retry-icon-hover': !loadingChatbotApi }"
                            *ngIf="!data.statusLoad"
                            (click)="getAnswerFromApi(idx)"
                        ></span>
                        <small>{{ data.question }}</small>
                    </div>
                </div>
                <div class="answer-wrapper">
                    <div
                        class="d-flex"
                        style="gap: 0.25rem"
                        *ngIf="data.answer != null"
                    >
                        <div class="answer-icon">
                            <img
                                src="../../../assets/svg/chatbot.svg"
                                alt="chatbot-icon"
                            />
                        </div>
                        <small>{{ data.answer }}</small>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="modal-footer"
            [ngStyle]="{ 'box-shadow': methodServices.statusDark ? '0 -5px 20px 0 rgba(255, 255, 255, 0.08)' : '0 -5px 20px 0 rgba(0, 0, 0, 0.08)' }"
        >
            <label class="w-100 custom-search">
                <input
                    type="text"
                    class="form-control"
                    [placeholder]="'What\'s on your mind?'"
                    [(ngModel)]="chatbotValue"
                    (keyup)="searchAnswer($event)"
                    (input)="searchAnswer($event)"
                    [disabled]="!methodServices.imageLoad || loadingChatbotApi"
                />
                <div
                    class="sent-notification-wrapper"
                    [ngStyle]="{ cursor: !methodServices.imageLoad || loadingChatbotApi ? 'default' : 'pointer', opacity: !methodServices.imageLoad || loadingChatbotApi ? '0.4' : '1' }"
                    (click)="clickSearchAnswer()"
                >
                    <img
                        src="../../../assets/svg/chatbot-sent.svg"
                        alt="chatbot-sent-icon"
                    />
                </div>
            </label>
        </div>
    </div>
</div>
