import {
    Component,
    OnInit,
    AfterViewInit,
    Input,
    EventEmitter,
    Output,
    OnChanges,
    ViewChild
} from '@angular/core';
import { MethodServices } from 'src/services/method-services';
import {MatDatepicker} from "@angular/material/datepicker";
import {MAT_DATE_LOCALE} from "@angular/material/core";
import * as _moment from 'moment';
import * as _rollupMoment from 'moment';
import {NGX_MAT_DATE_FORMATS, NgxMatDateAdapter, NgxMatDateFormats} from "@angular-material-components/datetime-picker";
import {NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, NgxMatMomentAdapter} from "@angular-material-components/moment-adapter";

const moment = _rollupMoment || _moment;

export const MY_FORMATS:NgxMatDateFormats = {
    parse: {
        dateInput: 'l, LTS',
    },
    display: {
        dateInput: 'LL',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'git-datetimepicker',
    templateUrl: 'git-datetimepicker.html',
    styleUrls: ['git-datetimepicker.scss'],
    providers: [
        {
            provide: NgxMatDateAdapter,
            useClass: NgxMatMomentAdapter,
            deps: [MAT_DATE_LOCALE, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        { provide: NGX_MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
})

export class gitDatetimepicker implements OnInit, AfterViewInit, OnChanges {
    @Output() changeDateValue = new EventEmitter<any>()
    @Input() modelDatepicker:any;
    @Input() modelTimepicker:any;
    @Input() gitClass:string;
    @Input() gitRequired:boolean = false;
    @Input() gitFormatDate:string;
    @Input() gitLabel:string;
    @Input() minDate?:any;
    @Input() maxDate?:any;
    @Input() isDisabled:boolean = false;
    @Input() minTime:any;
    @Input() maxTime:any;
    @Input() startView?:any

    firstLoad: boolean = true
    isDarkMode:boolean = false
    @ViewChild('picker') picker:any
    @ViewChild(MatDatepicker) datepicker: MatDatepicker<Date>;
    constructor(private methodServices: MethodServices) {
        this.methodServices.subject_DarkMode.subscribe((result)=>{
            this.methodServices.statusDark = result
            this.isDarkMode = result
        })

    }

    ngOnInit() {
        MY_FORMATS.display.dateInput = this.gitFormatDate
        this.minTime.setDate(this.modelDatepicker.getDate())
        this.maxTime.setDate(this.modelDatepicker.getDate())
    }

    ngAfterViewInit() {
        this.firstLoad = false
    }

    changeProcDate($event) {
        if (!this.firstLoad) {
            this.changeDateValue.emit(moment($event.value).toDate())
        }
    }

    ngOnChanges() {
        this.changeDateValue.emit(this.modelDatepicker)
    }

    openDatepicker() {
        if (this.isDisabled) {
            return
        }
        this.picker._selected = this.modelDatepicker == null ? moment() : moment(this.modelDatepicker)
        this.picker.open()
    }
}
