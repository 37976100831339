<style>
    .scroller{
        scrollbar-width: thin;
    }    

    ::-webkit-scrollbar{
        width:5px;
    }
    ::-webkit-scrollbar-thumb{
        background-color: lightgrey;
    }
    ::-webkit-scrollbar-thumb:hover{
        background-color: grey;
    }

    /* TIMELINE */
    .timeline-time {
        position:relative;
        border:0px solid grey;
        overflow-y:auto;
        overflow-x:hidden;
        width:100%;
    }
    /* .timeline-time::after {
        content:'';
        position:absolute;
        width:6px;
        height:100%;
        top:0;
        bottom:0;
        left:10.5px;
        background:rgba(185, 185, 185,0.5);
    } */
    .container-time{
        position:relative;
        left:30px;
        width:95%;
        background-color:transparent;
        padding:5px 15px;
        margin-bottom:10px;
    }

    /* CIRCLE */
    .container-time::after{
        content:'';
        position:absolute;
        top:50%;
        transform:translateY(-50%);
        left:-25px;
        width:17px;
        height:17px;
        border-radius:50%;
        border:4px solid #5e72e4 ;
        background:#79cbe4;
        z-index:1;
    }
    
    .content-time{
        background:rgba(128, 255, 255, 0.145098039215686);
        border-radius:10px;
        border:1px solid rgba(128, 255, 255, 0.8);
        padding:10px;
    }
    /* END TIMELINE */
</style>

<div style = 'height:380px' 
    class = 'd-flex justify-content-center align-items-center'
    *ngIf = "arrInput.length == 0">
    <div>
        <img src = "assets/img/brand/nodata(default).svg">
        
        <div class = 'text-center mt-1' style = 'font-weight:bold'>
            {{ 'No Approval Data' | translate }}
        </div>
    </div>
</div>

<div class = 'timeline-time scroller' *ngIf = "arrInput.length > 0">
    <!-- <div style = 'position:fixed;margin-left:20px;background:green;width:10px;height:100%;'>
        
    </div> -->
    <div *ngIf = "arrInput.length > 0" style = 'height:380px;'>
        <div class = 'container-time' *ngFor='let arrInput of arrInput;let isLast = last'>
            <div class = 'content-time'>
                <div class = 'clearfix'>
                    <div style = 'position:absolute;top:0px;left:-20px;width:6px;background:rgba(185, 185, 185,0.5)'
                        [ngStyle]="{'height':isLast ? '110%' : '105%'}"></div>
    
                    <table style = 'width:100%'>
                        <tr>
                            <td style = 'width:70px'>
                                <span class="avatar avatar-md rounded-circle" [ngStyle]="{'background-color': arrInput.backgroundAvatar}">
                                    <span class = ''
                                          style = 'font-size:30px'
                                          *ngIf = "arrInput.profilePicture == '' || arrInput.profilePicture == null">{{ arrInput.initial }}</span>
                                    <img [src] = 'arrInput.profilePicture' *ngIf = "arrInput.profilePicture != '' && arrInput.profilePicture != null" style="width:100%;height:100%;object-fit:cover" />
                                </span>
                            </td>
                            <td style = 'border:0px solid black;width:300px'>
                                <h3 style = 'font-weight:bold'>{{ arrInput.employeeName }}</h3>
                                <div style = 'font-size:13px' class = 'mt-3'>
                                    <span>{{ arrInput.employeeNo }}</span><br>
                                    <span>{{ arrInput.positionName }}</span><br>
                                    <span>{{ arrInput.actionDateLabel }}</span>
                                </div>
                                <div class = 'mt-3'>
                                    <h5>{{ 'Note:' | translate }}</h5>
                                    <div style = 'font-size:13px' class = 'mt--2'>
                                        {{ arrInput.actionNote }}
                                    </div>
                                </div>
                            </td>
                            <td style = 'text-align:right;border:0px solid red;' class = 'pl-4'>
                                <div style = 'position:relative;border:0px solid blue;width:150px'>
                                    <div style = 'position:absolute;width:150px;border:0px solid black;'>
                                        <div style = 'position:absolute;top:50%;transform:translateY(-50%)'>
                                            <span class = 'fas fa-check-circle text-success' style = 'font-size:35px' *ngIf = "arrInput.status == 'Approved'"></span>
                                            <span class = 'fas fa-times-circle text-danger' style = 'font-size:35px' *ngIf = "arrInput.status == 'Rejected'"></span>
                                            <span class = 'fas fa-times-circle text-danger' style = 'font-size:35px' *ngIf = "arrInput.status == 'Canceled'"></span>
                                        </div>
                                        <div style = 'position:absolute;top:50%;transform:translateY(-50%);' class = 'ml-5'>
                                            <span class = 'text-success' style = 'font-weight:bold;' *ngIf = "arrInput.status == 'Approved'">{{ 'Approved' | translate }}</span>
                                            <span class = 'text-danger' style = 'font-weight:bold' *ngIf = "arrInput.status == 'Rejected'">{{ 'Rejected' | translate }}</span>
                                            <span class = 'text-danger' style = 'font-weight:bold' *ngIf = "arrInput.status == 'Canceled'">{{ 'Canceled' | translate }}</span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- USAGE -->
<!-- <timeline-global></timeline-global> -->