import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataTypeServices } from 'src/services/data-type-services';

@Component({
    selector:'toggle-checkbox-component',
    templateUrl:'toggle-checkbox-component.html',
    styleUrls:['./toggle-checkbox-component.scss']
})

export class ToggleCheckboxComponent implements OnInit {
    @Input() labelName:string = "";
    @Input() checkboxValue:boolean = true;
    @Input() isDisabled:boolean = false;
    @Input() labelOn:string = "Yes";
    @Input() labelOff:string = "No";

    @Output() changeToggle:any = new EventEmitter<any>();

    constructor(public dataTypeServices:DataTypeServices){
     
    }

    ngOnInit(){}

    updateToggle(e) {
        this.changeToggle.emit(e)
    }
}