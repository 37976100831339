<div class = 'form-group' [ngClass]="{'required':gitRequired}">
    <label class = 'custom-calendar w-100'>
        <input type="text"
                class="{{gitClass}}"
                [ngClass]="isInvalidDate ? 'invalidError datepicker-field' : 'datepicker-field'"
                bsDaterangepicker
                placeholder = "{{placeholderRangePicker}}"
                [bsValue]="modelRangePicker"
                [value]="modelRangePicker | translate"
                autocomplete="off"
                autocapitalize="off"
                spellcheck="false"
                [minDate]="minDate"
                [maxDate]="maxDate"
                [bsConfig]="{ rangeInputFormat : gitFormatDate.toUpperCase(), dateInputFormat: gitFormatDate.toUpperCase(),
                        showTodayButton:true, adaptivePosition: true, showPreviousMonth:true, maxDateRange: rangePickerMaxDate }"
                (bsValueChange)='changeDateFilter($event)'
                [disabled]="isDisabled">
        <label class = 'form-input-label'>{{gitLabel | translate }}</label>
    </label>
</div>

<!-- Below is Example to Use Date RangePicker -->
<!-- <git-range-datepicker [gitFormatDate]="'dd-MMM-yyyy'"
                    [modelRangePicker]="modelSoldDate"
                    [gitLabel]="'Start Date - End Date'"
                    [isDisabled]="readOnlyInput"
                    [gitRequired]="true"
                    [rangePickerMaxDate]="60"
                    (changeDateRangeValue)="changeProcDate($event, 'soldDate')"
                    [gitClass]="''"></git-range-datepicker> -->