import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'myfilter',
    pure: false,
})

export class MyFilterPipe implements PipeTransform{
    transform(items: any[], filter: any) {
        if (!items || !filter){
            return items
        }
        return items.filter(item => item.typeColumn.indexOf(filter.typeColumn) !== -1)
    }
}