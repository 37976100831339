<div [ngClass]="{'container-fluid':true,'bg-transparent':true}">
  <div class="header-body">
    <div class="row mt-4 ml-2">
      <div class="col-12 p-0">
        <div class="h2 text-white d-inline-block mb-0">
          <i class="fas fa-home" style="cursor:pointer; padding:3px; font-size:14px; background-color: rgba(255, 102, 0, 1); border-radius: 5px;" (click)='backHome()'></i>
        </div>

        <nav aria-label="breadcrumb" class="d-inline-block ml-2">
          <ol class="breadcrumb breadcrumb-links">
            <li class="breadcrumb-item" *ngFor="let arrBreadCrumb of arrBreadCrumb; let i=index">
              <span style="cursor: pointer;" (click)='backTable(arrBreadCrumb)'> {{ arrBreadCrumb | lowercase | translate }} </span>
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div> 
</div>
                  